import { BoardStrength, BoardType, Box, Preset, Price, PrintType, Subcategory } from "./box";

export class CustomerChoices {
  cellLength = 0.375;
  cellWidth = 0.375;
  printing: string | string[] | undefined = undefined;
  material = "";
  outerMaterial = "";
  innerMaterial = "";
  enteredCustomQuantity = 0;
  length = 0;
  lengthSlots = 1;
  lengthWhole = 0;
  lengthFraction = 0;
  width = 0;
  widthSlots = 1;
  widthWhole = 0;
  widthFraction = 0;
  depth = 0;
  depthWhole = 0;
  depthFraction = 0;
  luxe = false;
  twoSided = false;
  insideOnly = false;
  selectedFinish = "Matte";
  selectedSubcategory: Subcategory | undefined = undefined;
  selectedVariant: { code: string; name: string } | undefined = undefined;
  selectedPreset: Preset | undefined = undefined;
  selectedBoardType: BoardType | undefined = undefined;
  selectedBoardStrength: BoardStrength | undefined = undefined;
  selectedPrintType: PrintType | undefined = undefined;
  selectedPrice: Price | undefined = undefined;
  selectedImageIndex: number = 0;
  selectedPriceDataBox: Box | undefined = undefined;
}
