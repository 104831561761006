<div class="page-container">
  @if (pageLoading()) {
    <app-loading-modal
      [content]="'loading styles'"
    ></app-loading-modal>
  }
  <app-page-content-wrapper [goBack]="goBack">
    <div class="page-title">
      <h1>
        @if (!state().uploadDesign) {
          Select a Style
        }
        @if (state().uploadDesign) {
          Upload Design: Select a Style
        }
      </h1>
    </div>
    <div class="page-content">
      <!-- web-tab-only -->
      @if (!failedcategories()) {
        <div class="row web-tab-only">
          @for (subcategory of subcategories(); track trackByFn($index, subcategory)) {
            <app-product-card
            [ngStyle]="subcategories().length < 3?{width:'50%'}:{width:'33%'}"
              [categoryCode]="state().categoryCode"
              [subcategory]="subcategory"
              [uploadDesign]="state().uploadDesign"
            ></app-product-card>
          }
        </div>
      }
      <!-- mobile-only -->
      @if (!failedcategories()) {
        <div class="mobile-only carousel-container">
          @for (
            subcategory of subcategories(); track trackByFn(i,
            subcategory); let i = $index) {
            <div
              class="carousel-item"
          [ngClass]="{
            'hidden-right': shownCategory() + 1 < i,
            'hidden-left': i < shownCategory() - 1,
            'left-item': i < shownCategory(),
            'right-item': shownCategory() < i
          }"
              >
              <div
                class="image-container col-xs-12"
            [routerLink]="[
              '/box-options',
              state().categoryCode,
              state().subcategoryCode,
              state().uploadDesign
            ]"
                >
                <img
                  [src]="getDefaultPicture(subcategory)"
                  (mouseover)="showGIF($event.target, subcategory)"
                  (mouseleave)="showStaticPicture($event.target, subcategory)"
                  />
              </div>
              <div class="product-header">
                <div [innerHTML]="subcategory.name"></div>
              </div>
              <div
                class="product-description"
                [innerHTML]="subcategoryDescriptions()[i]"
              ></div>
              <div class="button-container">
                <div
              [routerLink]="[
                '/box-options',
              state().categoryCode,
              state().subcategoryCode,
              state().uploadDesign
              ]"
                  class="link-container chevron-anchor red border"
                  >
                  <a class="web-tab-only">Build</a>
                  <a class="mobile-only">Select</a>
                </div>
              </div>
            </div>
          }
          <div class="chevron-container">
            <button
              class="chevron-button"
              [ngClass]="{ disabled: shownCategory() === 0 }"
            [ngStyle]="{
              background: 'url(assets/cb/images/round_chevron_left.svg)'
            }"
              (click)="previousCategory()"
            ></button>
            <button
              class="chevron-button"
            [ngClass]="{
              disabled: shownCategory() + 1 === subcategories().length
            }"
            [ngStyle]="{
              background: 'url(assets/cb/images/round_chevron_right.svg)'
            }"
              (click)="nextCategory()"
            ></button>
          </div>
        </div>
      }
    </div>
  </app-page-content-wrapper>
  <ng-template #notFoundModal>
    <app-not-found-modal></app-not-found-modal>
  </ng-template>
</div>
