import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: false,

  apiHost: "https://staging.apicustomboxes.office.fedex.com",
  builderUrl: "https://cdn.sandbox-mirakl.companybox.com/builder",
  isBuilderProduction: false,

  config: {
    name: "fedex",
    title: "Custom Branded Boxes By FedEx",
    logo: "assets/fedex/images/fedex_logo.png",
    enableHealthCheckPolling: true,
    healthCheckUrl:
      "https://staging3.office.fedex.com/default/timeout/session/heartbeat",
    marketingSite:
      "https://staging.office.fedex.com/default/custom-branded-boxes",
    fedexSellerId: 2008,
  },

  orderPrefix: "ST",

  logger: {
    logLevel: NgxLoggerLevel.TRACE,
    serverLogLevel: NgxLoggerLevel.OFF,
    serverLoggingUrl: undefined,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
