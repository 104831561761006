import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { inject } from "@angular/core";
import { ClientSessionStateService } from "./services/sessionState.service";
import { NGXLogger } from "ngx-logger";
import { ConfirmationModalComponent } from "./features/confirmation-modal/confirmation-modal.component";
import { ERROR_BODY, ERROR_TITLE } from "../globals";
import { BsModalService } from "ngx-bootstrap/modal";

export function requireSessionID(): CanActivateFn {
  return async (route: ActivatedRouteSnapshot) => {
    const authService = inject(AuthService);
    const stateSvc = inject(ClientSessionStateService);
    const logger = inject(NGXLogger);
    const modalSvc = inject(BsModalService);

    const sessionKey = route.queryParams["sessionID"];
    if (sessionKey) {
      stateSvc.updateState({ sessionKey: sessionKey });
    }

    if (stateSvc.getStateValue("sessionKey")) {

      try {
        const res = await authService.getAuthData().then((res: any) => {
          if (res.status === 401) {
            return res;
          }
          stateSvc.updateState({ authSessionData: res });
          return res;
        });
        if (res.status === 401) {
          modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
          return false;
        }
      } catch (error) {
        logger.error("Error getting auth data", error);
        return false;
      }

      try {
        await authService.getCurrentUser();
        return true;
      } catch (error) {
        logger.error("Error getting current user", error);
        modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        return false;
      }
    } else {
      logger.error("Missing sessionID");
      modalSvc.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      stateSvc.clearState();
      return false;
    }
  };
}

export function requireLogin(): CanActivateFn {
  return async (route: ActivatedRouteSnapshot,  state:RouterStateSnapshot) => {

    const authService = inject(AuthService);
    await requireSessionID()(route, state);
    return !!authService.getUser();
  };
}

export function refreshCustomerState(): CanActivateFn {
  return () => {
    inject(ClientSessionStateService).refreshState();
    return true;
  };
}
