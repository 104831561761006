import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { ClientSessionStateService } from "./services/sessionState.service";
import { inject } from "@angular/core";

export const sessionInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
    const stateSvc = inject(ClientSessionStateService);
    let newReq = req;
    const sessionKey = stateSvc.getStateValue("sessionKey")
    if(sessionKey) {
      const headers = req.headers.set('x-session', sessionKey);
      newReq = req.clone({ headers});
    }   
  return next(newReq);
};