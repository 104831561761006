import { CommonModule, Location } from "@angular/common";
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  Signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Observable, Subject, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import {
  BoardStrength,
  BoardType,
  Box,
  Preset,
  Price,
  PrintType,
  Subcategory,
} from "../../shared/models/box";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatExpansionModule } from "@angular/material/expansion";
import { LoadingModalComponent } from "../../features/loading-modal/loading-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NotFoundModalComponent } from "../../features/not-found-modal/not-found-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ERROR_BODY, ERROR_TITLE } from "../../../globals";
import { ConfirmationModalComponent } from "../../features/confirmation-modal/confirmation-modal.component";
import { BoxService } from "../../services/box.service";
import { ContainerService } from "../../services/container.service";
import { ImagesService } from "../../services/images.service";
import { ProjectService } from "../../services/project.service";
import Fraction from "../../shared/fractions";
import { DesignService } from "../../services/design.service";
import { NgxDropzoneModule } from "ngx-dropzone";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { UserIdleService } from "angular-user-idle";
import { AuthService } from "../../services/auth.service";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { PixabayModalComponent } from "./pixabay-modal/pixabay-modal.component";
import { PatternLibraryComponent } from "./pattern-library/pattern-library.component";
import { ShapeLibraryComponent } from "./shape-library/shape-library.component";

declare var UnityLoader: any;
declare var window: any;

import fileDownload from "js-file-download";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { ClientSessionStateModel } from "../../shared/models/sessionState.model";

@Component({
    selector: "app-design",
    templateUrl: "./design.component.html",
    styleUrls: ["./design.component.scss"],
    imports: [
        LoadingModalComponent,
        CommonModule,
        AlertModule,
        FormsModule,
        ButtonsModule,
        PopoverModule,
        PdfJsViewerModule,
        AccordionModule,
        MatSidenavModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSelectModule,
        MatIconModule,
        NgxDropzoneModule,
        MatInputModule,
        MatExpansionModule,
        MatProgressBarModule,
        NotFoundModalComponent,
        ImageUploadComponent,
        PixabayModalComponent,
        PatternLibraryComponent,
        ShapeLibraryComponent,
    ]
})
export class DesignComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  @ViewChild("login", { static: false }) loginModal!: TemplateRef<any>;
  @ViewChild("gameContainer", { static: false }) gameContainer!: ElementRef;
  @ContentChild("canvas", { static: false }) gameCanvas!: ElementRef;
  @ViewChild("leavingPageWarning", { static: false })
  leavingPageWarning!: TemplateRef<any>;
  @ViewChild("twoSidedWarning", { static: false })
  twoSidedWarning!: TemplateRef<any>;
  @ViewChild("helpModal", { static: false }) helpModal!: TemplateRef<any>;
  @ViewChild("notFoundModal", { static: false })
  notFoundModal!: TemplateRef<any>;
  @ViewChild("blankBoxWarning", { static: false })
  blankBoxModal!: TemplateRef<any>;
  @ViewChild("userIdle", { static: false }) userIdle!: TemplateRef<any>;

  private designerInstance: any;
  public designJSON: any;
  public insideSVG: string = "";
  public outsideSVG: string = "";

  public downloadUrl!: SafeUrl;

  public updatingImages = false;

  private design: any;

  public saveAfterGettingCanvas = false;

  private updating: boolean = false;
  // private subcategoryPresets: Preset[];

  subcategoriesFailed!: boolean;
  public selectedSubcategory: Subcategory | undefined;
  public selectedBoardType: BoardType | undefined;
  public selectedBoardStrength: BoardStrength | undefined;
  public selectedPrintType: PrintType | undefined;
  public selectedPreset!: Preset;
  public gloss = false;
  public twoSided = false;
  public insideOnly = false;
  public noPrinting = false;
  public expedited = false;

  public length = 0;
  public width = 0;
  public depth = 0;

  public quantity!: number;
  public quantities!: number[];
  public addedQuantities: number[] = [];
  public standardQuantities = [1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000];
  public luxeQuantities = [500, 1000, 2000, 3000, 5000, 8000, 10000];

  // public selectedPrice!: Price;

  // Ryan added variables
  private modal!: BsModalRef;
  public subcategoryCode!: string;
  public categoryCode!: string;
  public presetsList!: Preset[];
  public printing!: string;
  public printingOptions!: any[];
  public standard!: boolean;
  public luxe!: boolean;
  public material!: string;
  public innerMaterial!: string;
  public outerMaterial!: string;
  public materialOptions: any[] = [];
  public outerMaterialOptions: any[] = [];
  public innerMaterialOptions: any[] = [];
  public finish!: string;
  public finishOptions = ["Matte"];
  public quantityArrayIndex!: number;
  public displayPremiumWhite!: boolean;
  public displayWhite!: boolean;
  public displayKraft!: boolean;
  public displayTwoSided!: boolean;
  public displayLuxe!: boolean;
  public displayStandard!: boolean;
  // private isModalDisplay = true;
  public paperTypeId!: string;
  public boardTypeList: any[] = [];

  // Taylor added variables
  public designCategories = ["Patterns", "Shapes", "Text", "Images", "Review Details", "Help"];
  public containerID!: string;
  public patternCollections: any[] = [];
  public patterns: any[] = [];
  public shapeCollections: any[] = [];
  public shapes: any[] = [];
  public images: any[] = [];
  public designName = "My Box";
  public productDetails: any;

  // Kevin variables for Unity stuff
  private unityLoaded = false;

  // Liz variables for help views
  public helpView = false;
  public helpSection!: string;
  public isContinuing = false;
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
  public continueTwoSided = false;
  public date = new Date();

  private inside!: string;
  private outside!: string;
  private prod!: string;
  public isempty = false;
  public savingProject = false;
  public isOtherProduct = false;

  public twoSidedPrintingAdded = false;
  options!: string;

  public isPaddedMailer!: boolean;
  public rush!: boolean;
  isTape = false;
  addTextSrc: string = "";
  addImageSrc: string = "";
  addPixabaySrc: string = "";

  islabel = false;
  isTissue = false;
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();

  constructor(
    private boxService: BoxService,
    private bsModalService: BsModalService,
    private router: Router,
    private logger: NGXLogger,
    private readonly elementRef: ElementRef,
    private renderer: Renderer2,
    private projectService: ProjectService,
    private location: Location,
    private designService: DesignService,
    private idle: UserIdleService,
    public authService: AuthService,
    private imagesService: ImagesService,
    private containerService: ContainerService,
    private sanitizer: DomSanitizer,
    private stateSvc: ClientSessionStateService
  ) {}

  public activeButton = [true, false, false, false, false, false];
  public pageLoading!: boolean;

  async ngOnInit() {
    this.pageLoading = true;
    window.scroll(0, 0);
    window.finishLoading = this.finishLoadingWrapper(this);
    this.state = this.stateSvc.getState();

    const script = this.renderer.createElement("script");
    script.type = "text/javascript";
    script.src = "assets/builder/Build/UnityLoader.js";
    script.onload = () => {
      this.onUnityReady();
    };
    this.renderer.appendChild(this.elementRef.nativeElement, script);

    this.productDetails = this.state().productDetails;
    this.containerID = this.productDetails.ContainerName;
    this.categoryCode = this.productDetails.Category;
    this.subcategoryCode = this.productDetails.Subcategory ?? "";
    this.length = this.productDetails.length;
    this.width = this.productDetails.width;
    this.depth = this.productDetails.depth;
    this.printing = this.productDetails.print;
    this.material = this.productDetails.material;
    this.outerMaterial = this.productDetails.outerMaterial;
    this.innerMaterial = this.productDetails.innerMaterial;
    this.finish = this.productDetails.finish;
    this.options = this.productDetails.options;
    this.rush = this.productDetails.rush;
    this.quantity = this.productDetails.quantity;
    this.twoSided = this.productDetails.twoSided;
    this.insideOnly = this.productDetails.insideOnly;
    this.luxe = this.productDetails.luxe;
    this.gloss = this.productDetails.gloss;

    if (!this.subcategoryCode) {
      this.getPresets();
    } else {
      switch (this.subcategoryCode) {
        case "dhc":
          // this.isDHC = true;
          this.getDHC();
          break;
        case "rpm":
          this.isPaddedMailer = true;
          this.getPresets();
          break;
        default:
          this.getPresets();
      }
    }

    this.getImages();
    this.getPatternCollections();
    this.getShapeCollections();

    // window.SaveDesign = this.saveDesignWrapper(this);
    window.GoToCart = this.goToCartWrapper(this);
    window.GetImages = this.getImagesWrapper(this);
    window.ToggleInput = this.toggleInput(this);
    window.DownloadSVG = this.downloadSVGWrapper(this);
    window.DownloadPDF = this.downloadPDFWrapper(this);
    window.TwoSidedPrintingHint = this.twoSidedPrintingWrapper(this);
    window.HelpModal = this.helpModalWrapper(this);
  }

  ngOnDestroy(): void {
    this.changeUnityInput("0");
    this.designerInstance.SendMessage("SceneManager", "UnloadDesigner");
  }

  @HostListener("window:beforeunload", ["$event"])
  showLeaveWarning($event: any) {
    return false;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isContinuing && !this.savingProject && !this.isempty) {
      this.showModal(this.leavingPageWarning);
      return this.navigateAwaySelection$;
    }
    this.changeUnityInput("0");
    this.designerInstance.SendMessage("SceneManager", "UnloadDesigner");
    return true;
  }

  navigationChoice(choice: boolean) {
    this.navigateAwaySelection$.next(choice);
    this.hide();
  }

  public trackByFn(index: number, item: any) {
    return index;
  }

  // saveDesignWrapper(component: this) {
  //   return (json: string, insideSVG: any, outsideSVG: any) => {
  //     component.designJSON = JSON.parse(json);
  //     component.nameProject(insideSVG, outsideSVG);
  //   };
  // }

  continue() {
    this.idle.resetTimer();
    // this.isModalDisplay = true;
    this.modal.hide();
  }

  downloadSVGWrapper(component: this) {
    return (svg: any, filename: any) => {
      component.getSVG(svg, filename);
    };
  }

  getSVG(svg: any, filename: string) {
    fileDownload(svg, filename);
  }

  downloadPDFWrapper(component: this) {
    return (svg: any, filename: any) => {
      component.getPDF(svg, filename);
    };
  }

  getPDF(svg: any, filename: string) {
    this.containerService
      .convertBasicDesign(this.containerID, svg, filename)
      .then((response: any) => {
        this.downloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.apiHost}/project/container/${response.container}/${response.filename}/?download=true&downloadAs=${filename}`
        );
      })
      .catch(() => {
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  toggleInput(component: this) {
    document.addEventListener("click", (element) => {
      const target = element.target as HTMLInputElement;
      if (target.id === "#canvas") {
        component.changeUnityInput("1");
      } else {
        component.changeUnityInput("0");
      }
    });
  }

  twoSidedPrintingWrapper(component: this) {
    return (svg: any, filename: any) => {
      component.promptTwoSidedPrinting();
    };
  }

  promptTwoSidedPrinting() {
    this.showModal(this.twoSidedWarning);
  }

  addTwoSidePrinting() {
    this.printing = "Standard | 2-Sided";
    // close the menu so the new two-sided popup modal will not appear out of line
    this.changeActiveMenu("", -1);
    this.twoSided = true;
    this.updateForm();
    const location = this.getLocationRequest();
    this.designerInstance.SendMessage("Project Loader", "UpdateBoxConfig", JSON.stringify(location));
    this.twoSidedPrintingAdded = true;
  }

  changeUnityInput(focus: string) {
    if (this.designerInstance) {
      if (focus === "0") {
        this.designerInstance.SendMessage("Project Loader", "ToggleCaptureInput", "0");
      } else {
        this.designerInstance.SendMessage("Project Loader", "ToggleCaptureInput", "1");
      }
    }
  }

  goToCartWrapper(component: this) {
    return (json: any, insideSVG: any, outsideSVG: any) => {
      component.addDesignToCart(json, insideSVG, outsideSVG);
    };
  }

  getImagesWrapper(component: this) {
    return (prod: any, inside: any, outside: any, allSidesUsed: any) => {
      component.checkBlankBox(prod, inside, outside, allSidesUsed);
    };
  }

  finishLoadingWrapper(component: this) {
    return (loadStatus: any) => {
      component.finishLoading(loadStatus);
    };
  }

  helpModalWrapper(component: this) {
    return () => {
      this.showHelpModal();
    };
  }

  showHelpModal() {
    this.showModal(this.helpModal);
  }

  finishLoading(_loadStatus: number) {
    this.pageLoading = false;

    this.helpView = true;
    if (environment.config.name === "cb") {
      this.changeActiveMenu1("Help", 7);
    } else {
      this.hide();
    }

    if (JSON.parse(this.productDetails.continuedProject)) {
      this.designName = this.productDetails.name;
      this.loadExistingProject();
      this.printing = this.productDetails.print;
    }
  }

  loadExistingProject() {
    const location = {
      location: `${environment.apiHost}/project/design/${this.authService.getUser()!.id}/${this.productDetails.id}`,
    };
    this.designerInstance.SendMessage("Project Loader", "LoadFromJSONByLocationWithAuth", JSON.stringify(location));
  }

  ngAfterViewInit(): void {
    this.onUnityReady();
  }

  checkBlankBox(prod: string, inside: string, outside: string, allSidesUsed: any) {
    this.prod = prod;
    this.inside = inside;
    this.outside = outside;

    if (!allSidesUsed) {
      this.showModal(this.blankBoxModal);
    } else {
      this.saveCanvasImages();
    }
  }

  saveCanvasImages() {
    this.isContinuing = true;
    const base64Prod = "data:image/png;base64," + this.prod;
    const base64Outside = "data:image/png;base64," + this.outside;
    const base64Inside = "data:image/png;base64," + this.inside;

    this.containerService
      .updateFile(this.containerID, "screenshot.png", base64Prod)
      .then(() => {
        this.containerService
          .updateFile(this.containerID, "Texture_0.png", base64Outside)
          .then(() => {
            this.containerService
              .updateFile(this.containerID, "Texture_1.png", base64Inside)
              .then(() => {
                if (!this.saveAfterGettingCanvas) {
                  this.getDesignFromBuilder();
                }
                this.pageLoading = false;
              })
              .catch(() => {
                this.pageLoading = false;
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          })
          .catch(() => {
            this.pageLoading = false;
            this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch(() => {
        this.pageLoading = false;
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    if (!this.saveAfterGettingCanvas) {
      this.pageLoading = true;
    }
  }

  getPatternCollections() {
    this.designService.getPatternCollections().then((collections: any[]) => {
      collections
        .filter((collection: any) => {
          return collection.patterns > 0;
        })
        .forEach((collection: any) => {
          this.patternCollections.push(collection);
          this.designService.getPatterns(collection.id).then((patterns: { content: any[] }) => {
            patterns.content.forEach((pattern: { previewUrl: string }) => {
              if (pattern.previewUrl.substr(pattern.previewUrl.length - 3) === "png") {
                this.patterns.push(pattern);
              }
            });
          });
        });
    });
  }

  getCollectionPatterns(collectionId: number) {
    return this.patterns.filter((pattern) => {
      return pattern.collection.id === collectionId;
    });
  }

  getShapeCollections() {
    this.designService.getShapeCollections().then((collections: any[]) => {
      collections
        .filter((collection: { shapes: number }) => {
          return collection.shapes > 0;
        })
        .forEach((collection: { id: any }) => {
          this.shapeCollections.push(collection);
          this.designService.getShapes(collection.id).then((shapes: { content: any[] }) => {
            shapes.content.forEach((shape: any) => {
              this.shapes.push(shape);
            });
          });
        });
    });
  }

  getImages() {
    this.updatingImages = true;
    this.images = [];
    if (this.authService.getUser()?.id) {
      this.imagesService.getUserImages(this.authService.getUser()!.id!).then((images: any[]) => {
        images.forEach((image: { src: string; name: any }) => {
          image.src = `${environment.apiHost}/project/image/${this.authService.getUser()!.id!}/${image.name}`;
          this.images.push(image);
        });
        this.updatingImages = false;
      });
    } else {
      this.imagesService.getContainerFiles(this.containerID).then((container: { files: any[] }) => {
        container.files.forEach((file: { contentType: { type: string }; src: string; name: any }) => {
          if (file.contentType.type === "image") {
            file.src = `${environment.apiHost}/project/container/${this.containerID}/${file.name}/`;
            this.images.push(file);
          }
        });
        this.updatingImages = false;
      });
    }
    if (this.modal) {
      this.hide();
    }
  }

  getCollectionShapes(collectionId: number) {
    return this.shapes.filter((shape) => {
      return shape.collection.id === collectionId;
    });
  }

  goBack() {
    this.location.back();
  }

  onUnityReady(): void {
    if (!this.selectedPreset) {
      return;
    }
    const unityLoaderKey = "UnityLoader";
    const unityInstanceKey = "UnityDesignerInstance";
    if (this.designerInstance == null && window[unityLoaderKey] != null) {
      window[unityInstanceKey] = this.designerInstance = UnityLoader.instantiate(
        this.gameContainer.nativeElement.id,
        environment.builderUrl + "/builder.json",
        {
          onProgress: this.unityProgress.bind(this),
        }
      );
    }
  }

  getUser() {
    return this.authService.getUser();
  }

  removeImage(image: any) {
    if (this.authService.getUser()!.id) {
      this.imagesService
        .removeImage(this.authService.getUser()!.id!, image)
        .then(() => {
          this.getImages();
        })
        .catch(() => {
          this.updatingImages = false;
        });
    } else {
      this.imagesService
        .deleteContainerImage(this.containerID, image)
        .then(() => {
          this.getImages();
        })
        .catch(() => {
          this.updatingImages = false;
        });
    }
  }

  ngAfterContentChecked(): void {
    this.syncUnityCanvas();
  }

  syncUnityCanvas(): void {
    if (this.gameCanvas) {
      const c = this.gameCanvas.nativeElement;
      c.width = c.clientWidth * (window.devicePixelRatio || 1);
      c.height = c.clientHeight * (window.devicePixelRatio || 1);
    }
  }

  unityProgress(gameInstance: any, progress: any): void {
    if (!gameInstance.Module) {
      return;
    }
    if (!this.unityLoaded && progress >= 1) {
      // || (progress == 0.9 && this.lastProgress == 0.9)) {
      this.unityLoaded = true;

      console.log("unity ready", this, Date.now());

      const location = this.getLocationRequest();
      gameInstance.SendMessage("Project Loader", "LoadFromLocation", JSON.stringify(location));
    }
    // this.lastProgress = progress;
  }

  // designCategories[0] is 'Draft Designs' and designCategories[5] is 'Review Details'
  getClassName(designCategory: string) {
    switch (designCategory) {
      case this.designCategories[4]:
        return "category review-details";
      default:
        return "category " + designCategory.toLowerCase();
    }
  }

  changeActiveMenu(designCategory: string, index: number) {
    this.activeButton = this.activeButton.map((x) => false);
    this.activeButton[index] = true;
    if (designCategory === "Help") {
      window.scrollTo(0, document.body.scrollHeight);
    }

    if (index === -2) {
      this.helpView = false;
    }
  }

  changeActiveMenu1(designCategory: string, index: number) {
    this.activeButton = this.activeButton.map((x) => false);
    this.activeButton[index] = true;
    if (designCategory === "Help") {
      window.scrollTo(0, document.body.scrollHeight);
    }

    if (index === -2) {
      this.helpView = false;
    }
  }

  showModal(template: TemplateRef<any>, modalName?: string) {
    if (modalName) {
      this.modal = this.bsModalService.show(template, { class: modalName });
    } else {
      this.modal = this.bsModalService.show(template, { class: "modal-lg" });
    }
  }

  showHelpSection(section: string) {
    // used to show the help views
    this.helpView = true;
    this.helpSection = section;
    if (section === "elementExplanation" || section === "patternExplanation") {
      window.scrollTo(0, 0);
    } else if (section === "viewerExplanation") {
      window.scrollTo(window.width, document.body.scrollHeight);
    } else {
      window.scrollTo(window.width, 0);
    }
  }

  // This notifies Google Analytics when the user exits the guided tour
  logTourExitEvent(step: string) {
    if (window.ga) {
      window.ga("send", {
        hitType: "event",
        eventCategory: "Guided Tour",
        eventAction: "Exited Tour",
        eventLabel: step,
      });
    }
  }

  hide() {
    // hides all modals and help views
    // if the user was doing the tour, collapse the side menu when they exit
    console.log("before help view: " + this.helpView);
    if (this.helpView) {
      this.changeActiveMenu("", -1);
      this.logTourExitEvent(this.helpSection);
      this.helpSection = "";
    }
    this.helpView = false;
    if (this.modal) {
      this.modal.hide();
    }
    console.log("done help view: " + this.helpView);
  }

  showWhiteLogo(menu: string) {
    switch (menu) {
      case "addText":
        this.addTextSrc = "assets/cb/images/designer/add_text_white.svg";
        break;
      case "addImage":
        this.addImageSrc = "assets/cb/images/designer/add_image_white.svg";
        break;
      case "addPixabay":
        this.addPixabaySrc = "assets/cb/images/designer/add_pixabay_white.svg";
        break;
    }
  }

  showRedLogo(menu: string) {
    switch (menu) {
      case "addText":
        // this.addTextSrc = "assets/cb/images/designer/add_text.svg";
        break;
      case "addImage":
        // this.addImageSrc = "assets/cb/images/designer/add_image.svg";
        break;
      case "addPixabay":
        // this.addPixabaySrc = "assets/cb/images/designer/add_pixabay.svg";
        break;
    }
  }

  getDesignFromBuilder() {
    this.designerInstance.SendMessage("Project Loader", "GoToCart");
  }

  getDesignImages() {
    this.isContinuing = true;
    this.designerInstance.SendMessage("Project Loader", "GetImages");
  }

  async addDesignToCart(json: string, insideSVG: string, outsideSVG: string) {
    this.isContinuing = true;
    if (insideSVG !== "") {
      this.containerService
        .uploadBasicDesign(this.containerID, insideSVG, "online_inside.svg")
        .then((response: any) => {});
    }

    if (outsideSVG !== "") {
      this.containerService
        .uploadBasicDesign(this.containerID, outsideSVG, "online_outside.svg")
        .then((response: any) => {});
    }

    const outside = JSON.parse(json).outside;
    const inside = JSON.parse(json).inside;

    // console.log('Json', JSON.stringify(outside));
    this.imagesService
      .saveImageToContainer(this.containerID, "outside", JSON.stringify(outside))
      .then((res: { container: string; filename: string }) => {
        this.stateSvc.updateState({ container: res.container });
        this.stateSvc.updateState({ outsideFileName: res.filename });
      });
    this.imagesService
      .saveImageToContainer(this.containerID, "inside", JSON.stringify(inside))
      .then((res: { filename: string }) => {
        this.stateSvc.updateState({ insideFileName: res.filename });
      });
    // sessionStorage.setItem('outside', JSON.stringify(outside));
    // sessionStorage.setItem('inside', JSON.stringify(inside));

    const updatedProductDetails = {
      Category: this.productDetails.Category,
      Subcategory: this.productDetails.Subcategory,
      Code: this.productDetails.Code,
      BoardStrength: this.productDetails.BoardStrength,
      ContainerName: this.productDetails.ContainerName,
      paperType: this.getPaperTypeID(this.outerMaterial),
      secondaryPaperType: this.getPaperTypeID(this.innerMaterial),
      NewDesignID: 0,
      BoxDimensions: this.productDetails.BoxDimensions,
      Preset: this.productDetails.Preset,
      gloss: this.gloss,
      options: this.options,
      luxe: this.luxe,
      twoSided: this.twoSided,
      insideOnly: this.insideOnly,
      print: this.printing,
      material: this.material,
      finish: this.finish,
      categoryName: this.productDetails.categoryName,
      quantity: this.productDetails.quantity,
      outerMaterial: this.outerMaterial,
      innerMaterial: this.innerMaterial,
      shape: this.productDetails.shape,
      rush: this.productDetails.rush,

      // options: product.options
      // sample: product.sample
    };
    this.stateSvc.updateState({ productDetails: updatedProductDetails });

    this.router.navigate(["/review-box"]);
  }

  // addDraftDesign(draftDesign: any) {
  //   console.log(draftDesign);
  // }

  addPattern(pattern: any) {
    // alert(JSON.stringify(pattern));
    this.designerInstance.SendMessage("Design Manager", "AddPatternComponent", JSON.stringify(pattern));
  }

  addShape(shape: any) {
    const shapeRequest = {
      path: shape.content,
      type: shape.name.toLowerCase().replace(" ", "_"),
    };

    switch (shape.type.toLowerCase()) {
      case "triangle":
        this.designerInstance.SendMessage("Design Manager", "AddTriangleComponent");
        break;
      case "rect":
        this.designerInstance.SendMessage("Design Manager", "AddRectComponent");
        break;
      case "circle":
        this.designerInstance.SendMessage("Design Manager", "AddCircleComponent");
        break;
      case "poly":
        this.handlePolygon(shape);
        break;
      default:
        this.designerInstance.SendMessage("Design Manager", "AddShapeComponent", JSON.stringify(shapeRequest));
    }
  }

  handlePolygon(shape: any) {
    let points;
    switch (shape.name.toLowerCase()) {
      case "trapezoid 1":
        points = JSON.stringify({
          name: "trapezoid",
          points: [
            { x: -100, y: -50 },
            { x: 100, y: -50 },
            { x: 150, y: 50 },
            { x: -150, y: 50 },
          ],
        });
        break;
      case "emerald 1":
        points = JSON.stringify({
          name: "emerald",
          points: [
            { x: 0, y: -125 },
            { x: 108, y: -62.5 },
            { x: 108, y: 62.5 },
            { x: 0, y: 125 },
            { x: -108, y: 62.5 },
            { x: -108, y: -62.5 },
          ],
        });
        break;
      case "star 1":
        points = JSON.stringify({
          name: "trapezoid",
          points: [
            { x: 350, y: 75 },
            { x: 380, y: 160 },
            { x: 470, y: 160 },
            { x: 400, y: 215 },
            { x: 423, y: 301 },
            { x: 350, y: 250 },
            { x: 277, y: 301 },
            { x: 303, y: 215 },
            { x: 231, y: 161 },
            { x: 321, y: 161 },
          ],
        });
        break;
      case "star 2":
        points = JSON.stringify({
          name: "trapezoid",
          points: [
            { x: 0, y: 0 },
            { x: 100, y: 50 },
            { x: 200, y: 0 },
            { x: 150, y: 100 },
            { x: 200, y: 200 },
            { x: 100, y: 150 },
            { x: 0, y: 200 },
            { x: 50, y: 100 },
            { x: 0, y: 0 },
          ],
        });
        break;
    }
    this.designerInstance.SendMessage("Design Manager", "AddPolygonComponent", points);
  }

  addText() {
    this.designerInstance.SendMessage("Design Manager", "AddTextComponent");
  }

  addImage(image: any) {
    let imageUrl;
    if (!this.authService.getUser()!.id) {
      imageUrl = `${environment.apiHost}/project/container/${this.containerID}/${image.name}/`;
    } else {
      imageUrl = image.src;
    }

    this.designerInstance.SendMessage("Design Manager", "AddImageComponent", imageUrl);
  }

  addPixabay(pixabay: any) {
    this.designerInstance.SendMessage("Design Manager", "AddImageComponent", pixabay.imageURL);
  }

  /*            END OF THE UNITY SECTION         */

  getPresets() {
    let errorAdded = false;
    const searchCode = this.productDetails.continuedProject ? this.categoryCode : this.subcategoryCode;
    this.boxService.getSubcategory(searchCode).then(
      (subcategory: any) => {
        this.boxService.listPresets(subcategory.category.code, subcategory.code).then(
          (presets: Preset[]) => {
            this.presetsList = presets;
            this.selectedPreset = this.presetsList[0];
            this.updatePrintingOptions();
            this.onUnityReady();
            this.standard = true;
            // this.luxe = false;
            // this.twoSided = false;
            this.finish = "Matte";
            this.boardTypeList = this.selectedPreset.type!.subcategory!.boardTypes;
            // this.boardType = this.boardTypeList[0];
            this.selectedBoardType = this.boardTypeList[0];

            this.selectedSubcategory = this.selectedPreset.type!.subcategory;

            this.selectedSubcategory = this.selectedPreset.type!.subcategory;
            this.selectedBoardStrength = this.selectedPreset.boardStrengths[0];
            // this.boardStrength = this.selectedPreset.boardStrengths[0];
            // this.allowCustomSizes = this.selectedPreset.allowCustomSizes;
            this.displayPremiumWhite = this.selectedPreset.displayPremiumWhite!;
            this.displayWhite = this.selectedPreset.displayWhite!;
            this.displayKraft = this.selectedPreset.displayKraft!;
            // this.displaySecondaryWhite =
            //   this.selectedPreset.displaySecondaryWhite;
            // this.displaySecondaryKraft =
            //   this.selectedPreset.displaySecondaryKraft;
            this.displayTwoSided = this.selectedPreset.displayTwoSided!;
            this.displayLuxe = this.selectedPreset.displayLuxe!;
            this.displayStandard = this.selectedPreset.displayStandard!;
            // this.allowCustomSample = this.selectedPreset.allowCustomSample!;

            this.updateForm();
          },
          (reason: any) => {
            if (!errorAdded) {
              errorAdded = true;
              this.showModal(this.notFoundModal);
              // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load presets' });
              this.logger.error(reason);
            }
          }
        );
      },
      (reason: any) => {
        if (!errorAdded) {
          errorAdded = true;
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load presets' });
          this.logger.error(reason);
        }
      }
    );
  }

  getDHC() {
    const errorAdded = false;
    this.boxService.getSubcategory(this.subcategoryCode).then(
      (subcategory: any) => {
        this.selectedSubcategory = subcategory;
        this.standard = true;
        this.luxe = false;
        // this.customSizeEntered = false;
        this.twoSided = false;
        this.length = 8;
        // this.lengthWhole = 8;
        this.width = 4;
        // this.widthWhole = 4;
        this.depth = 0;
        this.printingOptions = [];
        this.finish = "Matte";
        this.boardTypeList = subcategory.boardTypes;
        // this.boardType = this.boardTypeList[0];
        this.selectedBoardType = this.boardTypeList[0];
        this.selectedBoardStrength = subcategory.boardTypes[0].boardStrengths[0];
        // this.mainGalleryImageURL = subcategory.images[0].location;
        // this.boardStrength =
        //   this.selectedSubcategory.boardTypes[0].boardStrengths[0];
        // this.allowCustomSizes = this.selectedSubcategory.allowCustomSizes;
        this.displayPremiumWhite = this.selectedSubcategory!.displayPremiumWhite!;
        this.displayWhite = this.selectedSubcategory!.displayWhite!;
        this.displayKraft = this.selectedSubcategory!.displayKraft!;
        // this.displaySecondaryWhite =
        //   this.selectedSubcategory.displaySecondaryWhite;
        // this.displaySecondaryKraft =
        //   this.selectedSubcategory.displaySecondaryKraft;
        this.displayTwoSided = this.selectedSubcategory!.displayTwoSided!;
        this.displayLuxe = this.selectedSubcategory!.displayLuxe!;
        this.displayStandard = this.selectedSubcategory!.displayStandard!;
        // this.allowCustomSample = this.selectedSubcategory!.allowCustomSample;

        // Sets default box photo.
        for (let i = 0; i < subcategory.images.length; i++) {
          if (subcategory.images[i].default) {
            // this.selectedImage = i;
            // this.mainGalleryImageURL = subcategory.images[i].location;
          }
        }
        this.listPrices();
        this.updateForm();
      },
      (reason: any) => {
        this.showModal(this.notFoundModal);
        // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load categories' });
        this.logger.error(reason);
      }
    );
  }

  // deleted getDHC() here;

  updateForm() {
    this.expedited = false;
    this.noPrinting = false;
    this.updatePrintingOptions();
    this.updateMaterialOptions();
    this.updateUnityBoxType();
  }

  getLocationRequest() {
    const containerUrl = this.projectService.getFileUrl("container/" + this.containerID);

    let allowTwoSidePrinting = true;

    if (
      this.isOtherProduct ||
      this.subcategoryCode === "SNAP2" ||
      this.subcategoryCode === "SNAP2R" ||
      this.subcategoryCode === "PIZ" ||
      this.subcategoryCode === "QPME"
    ) {
      allowTwoSidePrinting = false;
    }
    let hasInside = false;
    let hasOutside = false;

    let isInsideKraft = false;
    let isOutsideKraft = false;

    // if (this.twoSided) {
    hasOutside = true;
    hasInside = true;
    if (this.innerMaterial === "Kraft") {
      isInsideKraft = true;
    }

    if (this.outerMaterial === "Kraft") {
      isOutsideKraft = true;
    }
    if (!this.insideOnly && !this.twoSided) {
      hasInside = false;
      hasOutside = true;
    } else if (this.insideOnly) {
      hasInside = true;
      hasOutside = false;
    }

    //     if (this.material === 'Kraft') {
    //       isOutsideKraft = true;
    //       this.outerMaterial = 'Kraft';
    //     }

    //   } else {
    //     hasInside = true;
    //     hasOutside = false;

    //     if (this.material === 'Kraft') {
    //       isInsideKraft = true;
    //       this.innerMaterial = 'Kraft';
    //     }
    //  }

    const data = {
      location: containerUrl,
      code: this.selectedPreset.code,
      length: this.selectedPreset.length,
      width: this.selectedPreset.width,
      depth: this.selectedPreset.depth,
      label: this.selectedPreset.label,
      allowTwoSidePrinting,
      hasInside,
      hasOutside,
      isInsideKraft,
      isOutsideKraft,
      isProduction: environment.isBuilderProduction,
    };
    // console.log('-------------------------');
    console.log("Builder data", data);
    // console.log('-------------------------');
    return data;
  }

  updateUnityBoxType() {
    const location = this.getLocationRequest();
    this.designerInstance.SendMessage("Project Loader", "UpdateBoxConfig", JSON.stringify(location));
  }

  presetSuggestionSelected(preset: { id: number | undefined }) {
    // After user selects the suggest preset instead of custom sizing,
    // find the preset's ID in the existing presetsList and set that as the selectedPreset.
    this.presetsList.forEach((item, index) => {
      if (preset.id === this.presetsList[index].id) {
        this.selectedPreset = this.presetsList[index];
      }
    });
    // this.customSizeEntered = false;
    this.updateForm();
  }

  checkForViolations() {
    this.boxService
      .getPrice(
        this.boxCode,
        this.selectedBoardStrength?.strength ?? "",
        this.paperTypeId,
        this.length,
        this.width,
        this.depth,
        this.quantity,
        this.twoSided,
        this.luxe,
        this.gloss,
        this.noPrinting,
        this.options,
        this.expedited !== undefined ? this.expedited : false
      )
      .then(
        (value: any) => {},
        (reason: any) => {
          // if (!errorAdded) {
          //     errorAdded = true;
          //     this.toasty.error({title: 'Service Unavailable', msg: 'Unable to load presets'});
          //     this.logger.error(reason);
          // }
        }
      );
  }

  listPrices() {
    // this.pricesFailed = false;
    // this.prices = undefined;
    this.updating = true;
    // If One sided, paperTypeId is Material's ID.
    // If two sided, paperTypeID is premium white if inner is premium white,
    // else it is the outer material's ID.
    // if (!this.twoSided) {
    //   this.paperTypeId = this.getPaperTypeID(this.material);
    // } else {
    this.getPaperTypeID(this.innerMaterial) === "pkw"
      ? (this.paperTypeId = this.getPaperTypeID(this.innerMaterial))
      : (this.paperTypeId = this.getPaperTypeID(this.outerMaterial));
    // }

    if (this.finish === "Gloss") {
      this.gloss = true;
    } else {
      this.gloss = false;
    }

    this.quantities = [];
    if (this.standard) {
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else if (this.luxe) {
      this.luxeQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    }
    if (this.subcategoryCode === "SNAP2S") {
      this.options = "superseal";
    }
    if (this.subcategoryCode === "SNAP2RS") {
      this.options = "superseal";
    }
    this.boxService
      .listPrices(
        this.boxCode,
        this.selectedBoardStrength?.strength ?? "",
        this.paperTypeId,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.twoSided,
        this.luxe,
        this.noPrinting,
        this.options,
        this.expedited
      )
      .then(
        (priceData: {
          prices: string | any[];
          box: { violations: any[] };
          debug: any;
          presetMatches: Preset[];
          customSizeIncrease: number;
        }) => {
          if (priceData.prices.length > 0) {
            // if (!this.quantityArrayIndex) {
            //   if (this.standard) {
            //     this.selectedPrice = priceData.prices[2];
            //   } else {
            //     this.selectedPrice = priceData.prices[0];
            //   }
            // } else {
            //   this.selectedPrice = priceData.prices[this.quantityArrayIndex];
            // }
          }
          // this.violations = priceData.box.violations;
          // this.debug = priceData.debug;
          // this.prices = priceData.prices;
          // this.presetMatches = priceData.presetMatches;
          // this.customSizeIncrease = priceData.customSizeIncrease / 100;
          // this.boardTypeList = priceData.box.boxType.subcategory.boardTypes;
          const something = priceData.box.violations.find((violation: { warning: any }) => {
            return !violation.warning;
          });

          // this.hasViolationErrors = something !== undefined;
          this.updating = false;
        },
        (reason: any) => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.logger.error(reason);
          // this.prices = undefined;
          // this.pricesFailed = true;
          this.updating = false;
        }
      );
  }

  getPaperTypeID(paperType: string) {
    if (paperType === "Kraft") {
      return "k";
    } else if (paperType === "Premium White") {
      return "pkw";
    } else {
      return "kw";
    }
  }

  get boxCode() {
    let code = this.selectedSubcategory!.code ?? "" + this.selectedBoardType!.flute;
    if (this.selectedPreset && !this.selectedSubcategory!.allowCustomSizes) {
      code = this.selectedPreset.type!.code!;
    }
    return code;
  }

  get luxePrinting() {
    return this.selectedPrintType && this.selectedPrintType.id === "luxe";
  }

  updatePrintingOptions() {
    // Clear out printingOptions
    this.printingOptions = [];

    if (this.displayStandard) {
      this.printingOptions.push("Standard | Outside");
      if (this.displayTwoSided) {
        this.printingOptions.push("Standard | Inside", "Standard | 2-Sided");
      }
    }
    if (this.displayLuxe) {
      this.printingOptions.push("Luxe | Outside");
      if (this.displayTwoSided) {
        this.printingOptions.push("Luxe | Inside");
      }
    }
    // If printing not selected yet, default to first option.
    if (!this.printing && this.printingOptions.length > 0) {
      this.printing = this.printingOptions[0];
    }
  }

  printingSelected(printingType: any) {
    this.printing = printingType;

    if (this.printing === "Standard | Outside") {
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.standard = true;
      this.insideOnly = false;
      this.luxe = false;
      this.twoSided = false;
    } else if (this.printing === "Standard | Inside") {
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.standard = true;
      this.insideOnly = true;
      this.luxe = false;
      this.twoSided = false;
    } else if (this.printing === "Standard | 2-Sided") {
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.luxe) {
        this.quantityArrayIndex = 2;
      }
      this.standard = true;
      this.twoSided = true;
      this.luxe = false;
      this.insideOnly = false;
    } else if (this.printing === "Luxe | Outside") {
      // If changing from Standard -> Luxe, reset quantity to default.
      if (this.standard) {
        this.quantityArrayIndex = 0;
      }
      this.luxe = true;
      this.insideOnly = false;
      this.standard = false;
      this.twoSided = false;
    } else if (this.printing === "Luxe | Inside") {
      // If changing from Standard -> Luxe, reset quantity to default.
      if (this.standard) {
        this.quantityArrayIndex = 0;
      }
      this.luxe = true;
      this.insideOnly = true;
      this.standard = false;
      this.twoSided = false;
    }
    this.updateFinishOptions();
    this.updateForm();
  }

  updateMaterialOptions() {
    // Clear out materialOptions
    this.materialOptions = [];
    this.outerMaterialOptions = [];
    this.innerMaterialOptions = [];

    if (this.displayWhite) {
      this.materialOptions.push("Standard White");
      if (!this.isPaddedMailer) {
        this.outerMaterialOptions.push("Standard White");
      } else {
        this.outerMaterialOptions.push("White");
      }
      if (this.categoryCode !== "Event Boxes" && this.subcategoryCode !== "PIZ") {
        this.innerMaterialOptions.push("Standard White");
      }
    }

    if (this.displayPremiumWhite) {
      this.materialOptions.push("Premium White");

      // If inner material is premium white, remove this option from outer material
      if (this.innerMaterial !== "Premium White" && this.subcategoryCode !== "PIZ") {
        this.outerMaterialOptions.push("Premium White");
      }
      // If outer material is premium white, remove this option from inner material
      if (
        this.outerMaterial !== "Premium White" &&
        this.categoryCode !== "Event Boxes" &&
        this.subcategoryCode !== "PIZ"
      ) {
        this.innerMaterialOptions.push("Premium White");
      }
    }
    if (this.displayKraft) {
      this.materialOptions.push("Kraft");
      if (this.subcategoryCode !== "PIZ") {
        this.outerMaterialOptions.push("Kraft");
      }
      this.innerMaterialOptions.push("Kraft");
    }
    // If material not selected yet, default to first option.
    // if (!this.material && this.materialOptions.length > 0) {
    //   this.material = this.materialOptions[0];
    // }
    // If outer material not selected yet, default to first option.
    if (!this.outerMaterial && this.outerMaterialOptions.length > 0) {
      this.outerMaterial = this.outerMaterialOptions[0];
    }
    // If inner material not selected yet, default to first option.
    if (!this.innerMaterial && this.innerMaterialOptions.length > 0) {
      this.innerMaterial = this.innerMaterialOptions[0];
    }
    this.updateFinishOptions();
  }

  updateFinishOptions() {
    if (this.luxe && this.printing === "Luxe | Inside" && this.innerMaterial === "Premium White") {
      this.finish = "Gloss";
      this.finishOptions = ["Gloss"];
    } else if (this.luxe && this.printing === "Luxe | Outside" && this.outerMaterial === "Premium White") {
      this.finish = "Gloss";
      this.finishOptions = ["Gloss"];
    } else if (this.luxe && (this.innerMaterial !== "Premium White" || this.outerMaterial !== "Premium White")) {
      this.finish = "Matte";
      this.finishOptions = ["Matte"];
    } else {
      this.finishOptions = ["Matte"];
    }

    // Displays Finish if premium white is selected.

    if (this.standard && this.material === "Premium White") {
      this.finishOptions.push("Gloss");
    } else if (this.standard && (this.innerMaterial === "Premium White" || this.outerMaterial === "Premium White")) {
      this.finishOptions.push("Gloss");
    }
  }

  // Displays custom size fractions
  getFractionLabel(num: any) {
    return Fraction.getFractionLabel(num);
  }

  getWholeNumber(fraction: any) {
    const temp = String(fraction).split(".", 2);
    return parseFloat(temp[0]);
  }

  getFractionDecimal(fraction: any) {
    const temp = String(fraction).split(".", 2);
    return parseFloat("0." + temp[1]);
  }

  selectSubcategoryPreset(preset: Preset) {
    if (this.updating) {
      return;
    }
    // console.log('selected', preset);
    if (!this.selectedPreset || this.selectedPreset.id !== preset.id) {
      this.selectedPreset = preset;
      this.updateForm();
    }
  }

  selectPresetMatch(preset: Preset) {
    this.length = preset.length;
    this.width = preset.width;
    this.depth = preset.depth;
    // this.presetMatches = [];
    this.listPrices();
  }

  // addCustomQuantity() {
  //   if (this.quantity) {
  //     let valid = true;
  //     // this.quantityError = undefined;
  //     const quantity = +this.quantity;

  //     if (this.luxePrinting) {
  //       if (quantity === 1) {
  //         // this.quantityError =
  //         //   "Minimum order is " + this.box.boxType.luxeMinimumOrder;
  //         valid = false;
  //       } else {
  //         if (quantity < this.box.boxType.luxeMinimumOrder) {
  //           // this.quantityError =
  //           //   "Minimum order is " + this.box.boxType.luxeMinimumOrder;
  //           valid = false;
  //         }
  //       }
  //     } else {
  //       if (quantity === 1) {
  //         valid = this.box.boxType.subcategory.allowCustomSample;
  //       } else if (quantity < this.box.boxType.minimumOrder) {
  //         // this.quantityError =
  //         //   "Minimum order is " + this.box.boxType.minimumOrder;
  //         valid = false;
  //       }
  //     }
  //     if (quantity > this.box.boxType.maxQuantity) {
  //       // this.quantityError = "Maximum order is " + this.box.boxType.maxQuantity;
  //       valid = false;
  //     }
  //     if (valid) {
  //       // console.log('Custom Quantity', quantity);
  //       if (quantity > 0) {
  //         this.addedQuantities.push(quantity);
  //         this.listPrices();
  //       }
  //     }
  //   }
  // }

  removeQuantity(price: Price) {
    const idx = this.addedQuantities.indexOf(price.quantity);
    this.addedQuantities.splice(idx, 1);
    this.listPrices();
  }

  scroll(id: any) {
    const element = window.document.getElementById(id);
    element.scrollIntoView();
  }
}
