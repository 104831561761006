import { Component, OnDestroy, OnInit, Signal, signal, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router, RouterModule } from "@angular/router";
import { LoggerModule, NGXLogger } from "ngx-logger";
import { BoxService } from "../../services/box.service";
import { LoadingModalComponent } from "../../features/loading-modal/loading-modal.component";
import { ModalModule, BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { CommonModule } from "@angular/common";
import { NotFoundModalComponent } from "../../features/not-found-modal/not-found-modal.component";
import { ProductCardComponent } from "../../features/product-card/product-card.component";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";
import { AuthService } from "../../services/auth.service";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { ClientSessionStateModel } from "../../shared/models/sessionState.model";
import { Subscription } from "rxjs";

declare var window: any;

@Component({
    selector: "app-box-style",
    templateUrl: "./box-style.component.html",
    styleUrls: ["./box-style.component.scss"],
    imports: [
        CommonModule,
        PageContentWrapperComponent,
        RouterModule,
        LoadingModalComponent,
        NotFoundModalComponent,
        LoggerModule,
        ModalModule,
        MatSnackBarModule,
        ProductCardComponent,
    ]
})
export class BoxStyleComponent implements OnInit, OnDestroy {
  @ViewChild("notFoundModal", { static: false })
  notFoundModal!: TemplateRef<any>;

  private modal: BsModalRef | undefined;

  shownCategory = signal<number>(0);
  failedcategories = signal<boolean>(false);
  pageLoading = signal<boolean>(false);
  subcategories = signal<any[]>([]);
  subcategoryDescriptions = signal<any[]>([]);
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();

  constructor(
    private boxService: BoxService,
    private bsModalService: BsModalService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private router: Router,
    private stateSvc: ClientSessionStateService
  ) {}
  async ngOnInit() {
    window.scroll(0, 0);
    this.pageLoading.set(true);
    this.state = this.stateSvc.getState();

    this.subscriptions.add(
      this.route.paramMap.subscribe((params: ParamMap) => {

        const catCode: string = params.get("categoryCode") ?? "";
        const uploadDesign = Boolean(JSON.parse(params.get("uploadDesign") ?? "false"));

        this.logger.debug(
          `calling BoxStyleComponent.ngOnInit with categoryCode ${this.state().categoryCode} and uploadDesign ${
            this.state().uploadDesign
          }`
        );

        if(catCode) {
          this.stateSvc.updateState({ categoryCode: catCode, uploadDesign: uploadDesign });
          this.getSubcategories();
        } else {
          this.goBack();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goBack() {
    this.router.navigate(["/box-category"], {
      fragment: `upload=${this.state().uploadDesign}`,
    });
  }

  getSubcategories() {
    this.boxService
      .getCategory(this.state().categoryCode, true)
      .then(
        (category) => {
          this.failedcategories.set(false);
          if (category.subcategories.length === 1) {
            this.router.navigate([
              "/box-options",
              this.state().categoryCode,
              category.subcategories[0].code,
              this.state().uploadDesign,
            ]);
          } else {
            this.subcategories.set([...category.subcategories]);
          }
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          const snackBarRef = this.snackBar.open("Unable to load box styles", "X", {
            duration: 10000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["red-snackbar"],
          });
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });

          this.logger.error(reason);
          this.failedcategories.set(true);
        }
      )
      .finally(() => this.pageLoading.set(false));
  }

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template);
  }
  hide() {
    if (this.modal) this.modal.hide();
  }
  public trackByFn(_index: any, item: { id: any }) {
    if (!item) {
      return null;
    } else {
      return item.id;
    }
  }
  // the below all deal with the carousel for the mobile view
  getDefaultPicture(subcategory: any) {
    if (subcategory.images.length > 0) {
      for (const image of subcategory.images) {
        if (image.default) {
          return image.location;
        }
      }
      return subcategory.images[0].location;
    }
  }

  getGIF(subcategory: any) {
    for (const image of subcategory.images) {
      if (image.hover === "true") {
        return image.location;
      }
    }
    return this.getDefaultPicture(subcategory);
  }

  showGIF(div: any, subcategory: any) {
    div.style = "background: url(" + this.getGIF(subcategory) + ");";
  }

  showStaticPicture(div: any, subcategory: any) {
    div.style = "background: url(" + this.getDefaultPicture(subcategory) + ");";
  }

  previousCategory() {
    if (this.shownCategory() !== 0) {
      this.shownCategory.update((value) => value--);
    }
  }

  nextCategory() {
    if (this.shownCategory() !== this.subcategories().length - 1) {
      this.shownCategory.update((value) => value++);
    }
  }
}
