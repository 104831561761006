import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(protected httpClient: HttpClient, protected logger: NGXLogger) {}

  getRecentOrders() {
    const url = `${environment.apiHost}/ecom/customer/orders`;
    const params = {
      params: {
        pageSize: "5",
      },
    };

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url, params).subscribe(
        (response) => {
          response = this.processOrders(response);
          response = this.filterOrders(response);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  processOrders(response: any) {
    response.orders.forEach((order: any) => {
      order.noteAttributes.forEach((noteAttribute: any) => {
        order[noteAttribute.name] = noteAttribute.value;
      });

      order.showProof = this.showProof(order);
      order.showShipped = this.showShipped(order);
      order.showEstimatedShipping = this.showEstimatedShipping(order);
      order.delivery = this.getDelivery(order);
      order.received = this.getReceived(order);
      order.isReceived = this.isReceived(order);
      order.proofed = this.getProofed(order);
      order.isProofed = this.isProofed(order);
      order.proofApproved = this.getProofApproved(order);
      order.isProofApproved = this.isProofApproved(order);
      order.paymentCaptured = this.getPaymentCaptured(order);
      order.isPaymentCaptured = this.isPaymentCaptured(order);
      order.shipped = this.getShipped(order);
      order.isShipped = this.isShipped(order);
      order.completed = this.getCompleted(order);
      order.isCompleted = this.isCompleted(order);
      order.refunded = this.getRefunded(order);
      order.isRefunded = this.isRefunded(order);
      order.hasTracking = this.hasTracking(order);
      order.isDraft = this.isDraft(order);
    });
    return response;
  }

  filterOrders(response: any) {
    if (response.orders) {
      const orders: any[] = [];
      response.orders.forEach((order: { isCompleted: any; isRefunded: any; }) => {
        if (!order.isCompleted && !order.isRefunded) {
          orders.push(order);
        }
      });
      if (orders.length > 0) {
        response.orders = orders;
      } else if (response.orders.length > 3) {
        response.orders = response.orders.splice(0, 3);
      }
    }
    return response;
  }

  showProof(order: { properties: { [x: string]: any; }; }) {
    const key = "requiresProofing";
    return order.properties[key];
  }
  showShipped(order: { properties: { [x: string]: any; }; }) {
    const key = "requiresShipping";
    return order.properties[key];
  }
  isDraft(order: { properties: { [x: string]: any; }; }) {
    const key = "draft";
    return order.properties[key];
  }

  showEstimatedShipping(order: { properties: { [x: string]: any; }; }) {
    return !this.isRefunded(order) && this.showShipped(order) && !this.isShipped(order) && !this.isCompleted(order);
  }

  getDelivery(order: { [x: string]: any; }) {
    const key = "DELIVERY";
    const delivery = order[key];
    if (delivery) {
      return new Date(delivery);
    }
    return undefined;
  }

  getReceived(order: any) {
    const status = this.getStatus(order, "RECEIVED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }

  isReceived(order: { status: { hasOwnProperty: (arg0: string) => any; }; }) {
    return order.status.hasOwnProperty("RECEIVED");
  }

  getProofed(order: any) {
    const status = this.getStatus(order, "PROOFED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }
  isProofed(order: { status: { hasOwnProperty: (arg0: string) => any; }; }) {
    return order.status.hasOwnProperty("PROOFED");
  }

  getProofApproved(order: any) {
    const status = this.getStatus(order, "PROOF_APPROVED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }

  isProofApproved(order: { status: { hasOwnProperty: (arg0: string) => any; }; }) {
    return order.status.hasOwnProperty("PROOF_APPROVED");
  }

  getPaymentCaptured(order: any) {
    const status = this.getStatus(order, "PAYMENT_CAPTURED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }
  isPaymentCaptured(order: { status: { hasOwnProperty: (arg0: string) => any; }; }) {
    return order.status.hasOwnProperty("PAYMENT_CAPTURED");
  }

  getShipped(order: any) {
    const status = this.getStatus(order, "SHIPPED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }
  isShipped(order: { properties?: { [x: string]: any; }; status?: any; }) {
    return order.status.hasOwnProperty("SHIPPED");
  }

  getCompleted(order: any) {
    const status = this.getStatus(order, "COMPLETED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }
  isCompleted(order: { properties?: { [x: string]: any; }; status?: any; }) {
    return order.status.hasOwnProperty("COMPLETED");
  }

  getRefunded(order: any) {
    const status = this.getStatus(order, "REFUNDED");
    if (status) {
      return new Date(status);
    }
    return undefined;
  }
  isRefunded(order: { properties?: { [x: string]: any; }; status?: any; }) {
    return order.status.hasOwnProperty("REFUNDED");
  }

  getStatus(order: { status: { [x: string]: any; }; }, name: string) {
    const status = order.status[name];
    if (status && status != null) {
      return status;
    }
    return "";
  }

  hasTracking(order: { fulfillments: any[]; }) {
    let tracking = false;
    if (order.fulfillments) {
      order.fulfillments.forEach((fulfillment: { status: string; tracking_number: any; }) => {
        if (fulfillment.status === "success" && fulfillment.tracking_number) {
          tracking = true;
        }
      });
    }
    return tracking;
  }

  getConvertedContent(jobId: any, containerName: any, filename: any) {
    const delay = 2000;
    return new Promise((resolve, reject) => {
      const url = `${environment.apiHost}/project/order/job/${jobId}/content?containerName=${containerName}&filename=${filename}`;
      this.httpClient.get(url).subscribe(
        (response) => {
          if (response === null) {
            setTimeout(() => resolve(this.getConvertedContent(jobId, containerName, filename)), delay);
          } else {
            resolve(response);
          }
        },
        (error) => {
          console.log(error);
          reject(error);
        }
      );
    });
  }
}
