import { Injectable, signal, Signal } from "@angular/core";
import { ClientSessionStateModel } from "../shared/models/sessionState.model";

@Injectable({
  providedIn: "root",
})
export class ClientSessionStateService {
  private state = signal<ClientSessionStateModel>(
    new ClientSessionStateModel()
  );

  getState(): Signal<ClientSessionStateModel> {
    return this.state;
  }

  getStateValue(key: string): any {
    const record: any = this.state();
    return record[key];
  }

  updateState(updatedState: any): void {
    const rec = this.state();
    this.state.set({ ...rec, ...updatedState });
    this.updateSessionState();
  }

  private updateSessionState() {
    sessionStorage.setItem("clientSessionState", JSON.stringify(this.state()));
  }

  clearState(): void {
    this.state.set(new ClientSessionStateModel());
    sessionStorage.removeItem("clientSessionState");
  }

  refreshState(): void {
    const sessionState = sessionStorage.getItem("clientSessionState");
    if (sessionState) {
      this.state.set(JSON.parse(sessionState));
    }
  }
}
