<div class="page-container">
  @if (pageLoading) {
  <app-loading-modal [content]="'Loading upload details'"></app-loading-modal>
  } @if (uploadingFile) {
  <app-loading-modal [content]="'Uploading file'"></app-loading-modal>
  } @if (deletingFile) {
  <app-loading-modal [content]="'Deleting upload'"></app-loading-modal>
  }
  <app-page-content-wrapper [goBack]="goBack">
    <div class="page-title">
      <h1>Upload Your Design</h1>
    </div>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-1 left-column">
          @if (outsideOnly || state().uploadDetails.twoSided) {
          <div>
            <button
              (click)="setActiveButton('outside')"
              [ngClass]="{ active: activeButton === 'outside', inactive: activeButton !== 'outside' }"
            >
              <img src="assets/cb/images/upload_art_grey.svg" />
            </button>
            <div class="btn-label">Outside&nbsp;Art</div>
          </div>
          } @if (state().uploadDetails.insideOnly || state().uploadDetails.twoSided) {
          <div>
            <button
              (click)="setActiveButton('inside')"
              [ngClass]="{ active: activeButton === 'inside', inactive: activeButton !== 'inside' }"
            >
              <img src="assets/cb/images/upload_art_grey.svg" />
            </button>
            <div class="btn-label">Inside Art</div>
          </div>
          }
        </div>
        <div class="col-8 center-column">
          @if (activeButton === 'outside') {
          <div>
            @if (!outsideFileUploaded) {
            <div>
              <div class="upload-container">
                <ngx-dropzone id="outsideDrop" (change)="processDesign($event)">
                  <ngx-dropzone-label
                    ><img src="assets/cb/images/upload_art_grey.svg" />
                    <div class="web-only">DROP YOUR PDF HERE OR</div>
                    CLICK TO UPLOAD
                  </ngx-dropzone-label>
                  @for (f of outsideFiles; track trackBy($index, f)) {
                  <ngx-dropzone-preview [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                  }
                </ngx-dropzone>
                <p>
                  Upload images with at least <strong>300 DPI</strong> or it may result in pixelation. <br />Accepted
                  file type is pdf
                </p>
              </div>
            </div>
            } @if (outsideFileUploaded) {
            <div class="preview-container">
              <img class="preview" [src]="outsideImageSrc" />
              <a (click)="deleteFile('outside')" class="remove-button">REMOVE UPLOAD</a>
            </div>
            }
          </div>
          } @if (activeButton === 'inside') {
          <div>
            @if (!insideFileUploaded) {
            <div>
              <div class="upload-container">
                <ngx-dropzone id="insideDrop" (change)="processDesign($event)">
                  <ngx-dropzone-label
                    ><img src="assets/cb/images/upload_art_grey.svg" />
                    <div class="web-only">DROP YOUR PDF HERE OR</div>
                    CLICK TO UPLOAD
                  </ngx-dropzone-label>
                  @for (f of insideFiles; track trackBy($index, f)) {
                  <ngx-dropzone-preview [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                  }
                </ngx-dropzone>
              </div>
            </div>
            } @if (insideFileUploaded) {
            <div class="preview-container">
              <img class="preview" [src]="insideImageSrc" />
              <a (click)="deleteFile('inside')" class="remove-button">REMOVE UPLOAD</a>
            </div>
            }
          </div>
          } 
        </div>
        <div class="col-3 right-column">
          <div class="box-details-container">
            @if (!isPaddedMailer) {
            <h5 class="column-button">My Box Details</h5>
            } @if (isPaddedMailer) {
            <h5 class="column-button">My Mailer Details</h5>
            }

            <div class="column-header">{{ design?.boxType?.name?.toUpperCase() }}</div>
            <div class="details-content">
              {{ getWholeNumber(length) }}@if (convertDecimalToFraction(length)[0] !== '0') {
              <span
                ><sup>{{ convertDecimalToFraction(length)[0] }}</sup
                >&frasl;<sub>{{ convertDecimalToFraction(length)[1] }}</sub></span
              >
              } x
              {{ getWholeNumber(width) }}@if (convertDecimalToFraction(width)[0] !== '0') {
              <span
                ><sup>{{ convertDecimalToFraction(width)[0] }}</sup
                >&frasl;<sub>{{ convertDecimalToFraction(width)[1] }}</sub></span
              >
              } @if (getWholeNumber(depth) === 0) {
              <span> (in)</span>
              }
              <!-- Don't display the depth if it is equal to 0 -->
              @if (getWholeNumber(depth) !== 0) {
              <span>
                x {{ getWholeNumber(depth) }}@if (convertDecimalToFraction(depth)[0] !== '0') {
                <span
                  ><sup>{{ convertDecimalToFraction(depth)[0] }}</sup
                  >&frasl;<sub>{{ convertDecimalToFraction(depth)[1] }}</sub></span
                >
                } (in)</span
              >
              }
              <div class="details-content">{{ printingSide }} Printing</div>
              <div class="details-content">
                {{ design?.boardStrength }} ECT {{ printingSide }} {{ paperName }} Printing
              </div>
              <label for="quantity"
                ><span>Quantity</span>@if (!isPaddedMailer) {
                <span>(Price Per Box)</span>
                }</label
              >
              @if (!customQuantityEntered && !isPaddedMailer) {
              <mat-select
                id="quantity"
                name="quantity"
                [(ngModel)]="selectedPrice"
                [placeholder]="
                  selectedPrice
                    ? (selectedPrice.quantity | number) + ' (' + (selectedPrice.pricePerUnit | currency) + ') each'
                    : ''
                "
              >
                @for (price of prices; track trackBy($index, price)) {
                <mat-option [value]="price">
                  {{ price.quantity | number }}
                  ({{ price.pricePerUnit | currency }} each)
                </mat-option>
                }
              </mat-select>
              } @if (!customQuantityEntered && isPaddedMailer) {
              <mat-select
                id="quantity"
                name="quantity"
                [(ngModel)]="selectedPrice"
                [disabled]="!prices"
                placeholder="{{ selectedPrice?.label }}"
              >
                @for (price of prices; track trackBy($index, price)) {
                <mat-option [value]="price">
                  {{ price.label }}
                </mat-option>
                }
              </mat-select>
              } @if (customQuantityEntered) {
              <input matInput (focusin)="showModal(customQuantity)" [(ngModel)]="enteredCustomPrice" />
              } @if (!customQuantityEntered) {
              <a class="quantity-link" (click)="showModal(customQuantity)">Enter a Custom Quantity</a>
              } @if (customQuantityEntered) {
              <a class="quantity-link" (click)="customQuantityEntered = !customQuantityEntered"
                >Select a Preset Quantity</a
              >
              }
              <div class="subtotal-container">
                <div class="column-header">SUBTOTAL: {{ selectedPrice?.totalPrice | currency }}</div>
              </div>
              <div (click)="addToCart()" class="button-container">
                <div class="link-container chevron-anchor red border">
                  <a href="javascript:">ADD TO CART</a>
                </div>
              </div>
              <app-upload-preview #uploadPreview></app-upload-preview>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </app-page-content-wrapper>

  <ng-template #customQuantity>
    <div class="modal-content custom-quantity">
      <div class="modal-body">
        <div class="container-fluid">
          <mat-label for="printing">Enter Custom Quantity</mat-label><br />
          <input
            matInput
            [ngModel]="state().quantity"
            (ngModelChange)="updateState('quantity', $event)"
            id="printing"
            (keyup.enter)="getPrice(); modal?.hide()"
          />
        </div>
        <div class="button-container">
          <button type="button" class="white-submit" (click)="getPrice(); hideModal()">OK</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
