import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Container, ContainerFile, Pageable, Project, UserImage } from "../shared/models";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(protected httpClient: HttpClient, private logger: NGXLogger, private authService: AuthService) {}

  createOrderItem(design: any) {
    const headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return new Promise<any>((resolve, reject) => {
      const payload = JSON.stringify(design);
      this.httpClient.post(`${environment.apiHost}/project/design/order/`, payload, headers).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listProjects(
    userId: number,
    page: number,
    options: SearchOptions = {
      includeUser: false,
      includeType: false,
      createdBy: "ALL",
    }
  ): Promise<Pageable<Project>> {
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUser().then(
        (user: { id: any }) => {
          // this.logger.debug('Loading projects');
          let url = `${environment.apiHost}/project/design/${user.id}/list/page?`;

          if (page > 0) {
            const offset = page - 1;
            url += `offset=${offset}&`;
          }

          url += `limit=${options.pageSize ? options.pageSize : "10"}&includeType=${
            options.includeType ? options.includeType : "false"
          }&
                subcategory=${options.subcategory ? options.subcategory : "false"}&designType=${
            options.designType ? options.designType : "ALL"
          }&`;

          url += `sort=-createdTime`;
          this.httpClient.get(url).subscribe(
            (response: any) => {
              const list: Project[] = [];
              (response.content as any[]).forEach((object) => {
                const project: Project = new Project().deserialize(object);
                list.push(project);
              });
              const result: Pageable<Project> = new Pageable<Project>().deserialize(response);
              result.content = list;
              this.logger.debug("Loaded projects", result);
              resolve(result);
            },
            (error) => {
              this.logger.debug(error.message || error);
              reject(error.message || error);
            }
          );
        },
        (reason: any) => {
          this.logger.debug(reason);
          reject(reason);
        }
      );
    });
  }

  getProjectById(projectId: string, includeType?: boolean): Promise<Project> {
    // this.logger.debug('Loading project ' + projectId + ' by user ' + userId);
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUser().then(
        (user: { id: any }) => {
          const url = `${environment.apiHost}/project/design/${user.id}/${projectId}?includeType=${
            includeType ? includeType : "false"
          }`;
          this.httpClient.get(url).subscribe(
            (response: any) => {
              const project: Project = new Project().deserialize(response);
              this.logger.debug("Loaded project", project);
              resolve(project);
            },
            (error) => {
              this.logger.debug(error.message || error);
              reject(error.message || error);
            }
          );
        },
        (reason: any) => {
          this.logger.debug(reason);
          reject(reason);
        }
      );
    });
  }

  getProjectByContainer(containerName: string, includeUser: boolean, includeType: boolean, includeScreenshot =false): Promise<Project> {
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUser().then(
        (user: { id: any }) => {
        const url = `${environment.apiHost}/project/design/${user.id}/container/${containerName}?includeUser=${includeUser}&includeType=${includeType}&includeScreenshot=${includeScreenshot}`;
        return this.httpClient.get(url).subscribe(
          (response: any) => {
            const project: Project = new Project().deserialize(response);
            this.logger.debug("Loaded project", project);
            resolve(project);
          },
          (error) => {
            this.logger.debug(error.message || error);
            reject(error.message || error);
          }
        );
      });
    });
  }

  getContainerFiles(container: string): Promise<Container> {
    return new Promise((resolve, reject) => {
      // this.logger.debug('Loading container ' + container);
      const url = `${environment.apiHost}/project/container/${container}/files?includeContent=false`;
      this.httpClient.get(url).subscribe(
        (response: any) => {
          resolve(new Container().deserialize(response));
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  getContainerFileDescriptor(container: string, filename: string, includeContent?: string): Promise<ContainerFile> {
    return new Promise((resolve, reject) => {
      // this.logger.debug('Loading container ' + container);
      const url = `${
        environment.apiHost
      }/project/container/${container}/files/content/?filename=${filename}&includeContent=${
        includeContent ? includeContent : "false"
      }`;

      this.httpClient.get(url).subscribe(
        (response: any) => {
          resolve(new ContainerFile().deserialize(response.file));
        },
        (error) => {
          reject(error.message || error);
        }
      );
    });
  }

  getContainerFileUrl(container: string, filename: string) {
    return `${environment.apiHost}/project/container/${container}/${filename}`;
  }

  getFileUrl(partial: string) {
    return `${environment.apiHost}/project/${partial}`;
  }

  getImages(userId: number): Promise<UserImage[]> {
    const url = `${environment.apiHost}/project/image/${userId}/list`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res: any) => {
          const images: UserImage[] = [];
          res.forEach((value: any) => {
            const userImage: UserImage = new UserImage().deserialize(value);
            this.logger.debug("Loaded user images", userImage);
            images.push(userImage);
          });
          resolve(images);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  getImage(userId: number, name: string): Promise<UserImage> {
    const url = `${environment.apiHost}/project/image/${userId}/${name}`;

    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (res: any) => {
          const userImage: UserImage = new UserImage().deserialize(res);
          this.logger.debug("Loaded user images", userImage);
          resolve(userImage);
        },
        (error) => {
          this.logger.debug(error.message || error);
          reject(error.message || error);
        }
      );
    });
  }

  deleteDesignFile(file: ContainerFile): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUser().then(
        (user: { id: any }) => {
          const url = `${environment.apiHost}/project/container/${file.containerName}/design/${file.shortName}/?userId=${user.id}`;
          this.httpClient.delete(url, {}).subscribe(
            (response: any) => {
              this.logger.debug("Deleted file from container", file.shortName, file.containerName);
              resolve(true);
            },
            (error) => {
              this.logger.debug(error.message || error);
              reject(false);
            }
          );
        },
        (error: { message: any }) => {
          this.logger.debug(error.message || error);
          reject(false);
        }
      );
    });
  }

  getDesignUploadUrl(container: string, filename: string): string {
    return `${environment.apiHost}/zuul/project/container/${container}/upload/design?filename=${filename}&convert=false`;
  }

  getBasicDesigns() {
    return new Promise((resolve, reject) => {
      this.authService.getCurrentUser().then(
        (user: { id: any }) => {
          this.httpClient
            .get(`${environment.apiHost}/project/design/${user.id}/list?designType=basic&theme=`)
            .subscribe(
              (response: any) => {
                resolve(response);
              },
              (error) => {
                reject(error || error.message);
              }
            );
        },
        (error: { message: any }) => {
          this.logger.debug(error.message || error);
          reject(false);
        }
      );
    });
  }

  getDesignForContainer(containerId: any, user: { id: any }) {
    const url = `${environment.apiHost}/project/design/${user.id}/container/${containerId}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (design) => {
          resolve(design);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getDesignForUser(designId: any, user: { id: any }) {
    const url = `${environment.apiHost}/project/design/${user.id}/${designId}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (design) => {
          resolve(design);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export interface SearchOptions {
  includeUser?: boolean;
  includeType?: boolean;
  createdBy?: string;
  pageSize?: number | 10;
  subcategory?: string;
  designType?: string;
  preset?: string;
}
