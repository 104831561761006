import { Component, ElementRef, OnInit, Signal, TemplateRef, ViewChild, signal } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BoxService } from '../../services/box.service';
import { Price } from '../../shared/models';
import { Subcategory } from '../../shared/models';
import Fractions from '../../shared/fractions';
import { NotFoundModalComponent } from '../../features/not-found-modal/not-found-modal.component';
import { LoadingModalComponent } from '../../features/loading-modal/loading-modal.component';
import { ProjectService } from '../../services/project.service';
import { ImagesService } from '../../services/images.service';
import { ContainerService } from '../../services/container.service';
import { ERROR_BODY, ERROR_TITLE } from '../../../globals';
import { CheckoutService } from '../../services/checkout.service';
import { ConfirmationModalComponent } from '../../features/confirmation-modal/confirmation-modal.component';
import { PageContentWrapperComponent } from '../../features/page-content-wrapper/page-content-wrapper.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { ClientSessionStateModel } from '../../shared/models/sessionState.model';
import { ClientSessionStateService } from '../../services/sessionState.service';

@Component({
    selector: "app-review-box",
    templateUrl: "./review-box.component.html",
    styleUrls: ["./review-box.component.scss"],
    imports: [
        PageContentWrapperComponent,
        LoadingModalComponent,
        NotFoundModalComponent,
        CommonModule,
        MatOptionModule,
        MatCheckboxModule,
        FormsModule,
        MatSelectModule,
        MatOptionModule,
    ]
})
export class ReviewBoxComponent implements OnInit {
  @ViewChild("notFoundModal", { static: false })
  notFoundModal!: TemplateRef<any>;
  @ViewChild("addToCartForm", { static: false }) addToCartForm!: ElementRef;

  public box: any;
  public selectedPrice: Price = new Price();
  selectedSubcategory: Subcategory | undefined;
  public prices: Price[] = [];
  public pageLoading: boolean = false;
  public cartLoading: boolean = false;

  public isTape = false;
  public isTissue = false;
  public isLabel = false;
  public isPizza = false;
  public isPaddedMailer = false;

  // default to outside only printing
  public showOutsideButton = true;
  public showInsideButton = false;

  private quantities: number[] = [];
  public quantityArrayIndex = 0;
  public standardQuantities = [
    1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000,
  ];
  private luxeQuantities = [500, 1000, 2000, 3000, 5000, 8000, 10000];

  public customQuantityEntered = false;
  public customQuantityViolation = false;

  public length: number = 0;
  public width: number = 0;
  public depth: number = 0;

  private boxData: any;

  public prodImage: any;
  public insideImage: any;
  public outsideImage: any;

  public activeButton = "outsideArt";
  options: string = "";
  modal!: BsModalRef<unknown>;
  disableBtn = signal<boolean>(false);
  cartUrl = signal<string>("");
  cxmlurlencoded = signal<string>("");
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();

  constructor(
    private location: Location,
    private projectService: ProjectService,
    private bsModalService: BsModalService,
    private boxService: BoxService,
    private imagesService: ImagesService,
    private sanitizer: DomSanitizer,
    private containerService: ContainerService,
    private checkoutService: CheckoutService,
    public stateSvc: ClientSessionStateService
  ) {}

  ngOnInit() {
    this.pageLoading = true;
    window.scroll(0, 0);
    this.state = this.stateSvc.getState();

    this.checkPrintingSide();

    this.containerService
      .getContainerFiles(this.state().productDetails.ContainerName)
      .then((response: any) => {
        const outsideFile = response.files.find(
          (x: { name: string }) => x.name === "Texture_0.png"
        ).content;
        const insideFile = response.files.find(
          (x: { name: string }) => x.name === "Texture_1.png"
        ).content;

        this.outsideImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpeg;base64, " + outsideFile
        );
        this.insideImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpeg;base64, " + insideFile
        );
        this.pageLoading = false;
      })
      .catch(() => {
        this.pageLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    if (this.state().productDetails.BoxDimensions) {
      const dimensions = this.state().productDetails.BoxDimensions?.split("x");
      this.length = dimensions ? parseInt(dimensions[0]??0): 0;
      this.width = dimensions ? parseInt(dimensions[1]??0) : 0;
      this.depth = dimensions ? parseInt(dimensions[2]??0) : 0;
    }

    if (this.state().productDetails.Code?.startsWith("PIZ")) {
      this.isPizza = true;
      this.listPizzaPrices();
    } else if (this.state().productDetails.Code?.startsWith("RPM")) {
      this.isPaddedMailer = true;
      this.listPaddedMailerPrices();
    } else {
      this.listPrices();
    }
    this.boxService
      .getType(this.state().productDetails.Code!)
      .then((response) => {
        this.boxData = response;
        this.boxData.flute = response.boardType?.flute;
        this.boxData.overlay = false;
        this.boxData.preConstructedZip = false;
        if (
          this.state().productDetails !== null &&
          this.state().productDetails.Subcategory !== null &&
          this.state().productDetails.Subcategory!.startsWith("SNAP2")
        ) {
          this.boxData.options = "superseal";
        }
        this.boxData.nameAsHtml = {};
        this.boxData.defaultQuantity = 200;
      })
      .catch(() => {
        this.pageLoading = false;
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  goBack() {
    this.location.back();
  }

  getPrintingSide() {
    const i = this.state().productDetails?.print?.indexOf("") || 0;
    return (this.state().productDetails.print! as string).substring(i).trim() || "";
  }

  getPrintingType() {
    const i = this.state().productDetails?.print?.indexOf(" ") || 0;
    return (this.state().productDetails?.print! as string).substring(0, i).trim() || "";
  }

  setActiveButton(buttonName: string) {
    this.activeButton = buttonName;
  }

  // showOutsideButton is already set to true
  checkPrintingSide() {
    if (this.state().productDetails?.insideOnly) {
      this.showInsideButton = true;
      this.showOutsideButton = true;
      this.activeButton = "insideArt";
    } else if (this.state().productDetails?.twoSided) {
      this.showInsideButton = true;
    }
  }

  listPrices() {
    this.prices = [];
    let paperType;
    if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "pkw" ||
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "pkw"
    ) {
      paperType = "pkw";
      // } else if (this.state().productDetails.categoryCode === 'Event Boxes') {
      //   this.state().productDetails.paperTypeId = this.getPaperTypeID(this.state().productDetails.outerMaterial);
    } else if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "kw" &&
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "k"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "k" &&
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "kw"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "k" ||
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "k"
    ) {
      paperType = "k";
    } else {
      paperType = "kw";
    }

    this.quantities = [];
    if (
      this.getPrintingType() === "Standard" ||
      this.state().productDetails.print!.includes("Standard")
    ) {
      this.standardQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    } else {
      this.luxeQuantities.forEach((quantity) => {
        this.quantities.push(quantity);
      });
    }

    this.boxService
      .getPrice(
        this.state().productDetails.Code??"",
        this.state().productDetails.BoardStrength??"",
        paperType,
        this.length,
        this.width,
        this.depth,
        this.state().quantity,
        this.state().productDetails.twoSided??false,
        this.state().productDetails.luxe??false,
        this.state().productDetails.gloss??false,
        false,
        this.state().productDetails.options,
        this.state().productDetails.rush??false
      )
      .then((priceResponse) => {
        this.selectedPrice = priceResponse.price ?? new Price();
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .listPrices(
        this.state().productDetails.Code ?? "",
        this.state().productDetails.BoardStrength ?? "",
        paperType,
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.state().productDetails.twoSided??false,
        this.state().productDetails.luxe??false,
        false,
        this.state().productDetails.options,
        this.state().productDetails.rush??false
      )
      .then(
        (priceData) => {
          this.selectedSubcategory = priceData.box?.boxType?.subcategory;
          const allowCustomSample = this.selectedSubcategory?.allowCustomSample;
          if (this.state().productDetails.luxe) {
            priceData.prices = priceData.prices.filter(
              (x: Price) =>
                (priceData.prices[0] &&
                  x.quantity >= priceData.prices[0].luxeMinimumOrder &&
                  x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder && x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          }

          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }
  getPaperTypeID(paperType: string) {
    if (paperType === "Kraft") {
      return "k";
    } else if (paperType === "Premium White") {
      return "pkw";
    } else {
      return "kw";
    }
  }
  listPizzaPrices() {
    this.prices = [];

    this.quantities = [
      500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000, 6000, 7000,
      8000, 9000, 10000, 11000, 12000,
    ];

    this.boxService
      .getPrice(
        this.state().productDetails.Code ?? "",
        this.state().productDetails.BoardStrength ?? "",
        this.state().productDetails.paperType??"",
        this.length,
        this.width,
        this.depth,
        this.state().quantity,
        this.state().productDetails.twoSided??false,
        this.state().productDetails.luxe??false,
        this.state().productDetails.gloss??false,
        false,
        this.state().productDetails.options,
        false
      )
      .then((priceResponse) => {
        this.selectedPrice = priceResponse.price ?? new Price();
      })
      .catch(() => {
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    this.boxService
      .listPrices(
        this.state().productDetails.Code ?? "",
        this.state().productDetails.BoardStrength ?? "",
        this.state().productDetails.paperType??"",
        this.length,
        this.width,
        this.depth,
        this.quantities,
        this.state().productDetails.twoSided??false,
        this.state().productDetails.luxe??false,
        false,
        this.state().productDetails.options,
        false
      )
      .then(
        (priceData) => {
          this.selectedSubcategory = priceData.box.boxType?.subcategory;
          const allowCustomSample = this.selectedSubcategory?.allowCustomSample;
          if (!this.state().productDetails.luxe) {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder && x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].luxeMinimumOrder && x.quantity <= priceData.prices[0].maxQuantity) ||
                (x.quantity === 1 && allowCustomSample)
            );
          }

          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }

  listPaddedMailerPrices() {
    let paperTypeName = "white";
    if (this.state().productDetails.material === "Kraft") {
      paperTypeName = "kraft";
    }

    this.boxService
      .getPaddedMailerPrice(
        this.state().productDetails.Code??"",
        paperTypeName,
        this.state().quantity,
        false
      )
      .then((priceResponse: any) => {
        this.selectedSubcategory = priceResponse.type.subcategory;
        this.selectedPrice = priceResponse.price;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    switch (this.state().productDetails.Code) {
      case "RPM00Z":
        this.quantities = [1, 2, 10, 20, 100];
        break;
      case "RPM02Z":
      case "RPM05Z":
        this.quantities = [1, 5, 20, 50, 100, 200];
        break;
      case "RPM06Z":
        this.quantities = [1, 10, 50, 150, 250, 500];
        break;
    }

    this.boxService
      .listPaddedMailerPrices(
        this.state().productDetails.Code??"",
        paperTypeName,
        this.quantities,
        false
      )
      .then(
        (priceData: any) => {
          // priceData.prices = priceData.prices.filter(x => x);
          // priceData.prices.forEach(price => price.pricePerUnit = price.pricePerCase);
          this.prices = priceData.prices;
        },
        () => {
          this.showModal(this.notFoundModal);
          // this.toasty.error({ title: 'Service Unavailable', msg: 'Unable to load prices' });
          this.prices = [];
        }
      );
  }

  getPrice() {
    let paperType;
    if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "pkw" ||
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "pkw"
    ) {
      paperType = "pkw";
      // } else if (this.state().productDetails.categoryCode === 'Event Boxes') {
      //   this.state().productDetails.paperTypeId = this.getPaperTypeID(this.state().productDetails.outerMaterial);
    } else if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "kw" &&
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "k"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "k" &&
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "kw"
    ) {
      paperType = "kw";
    } else if (
      this.getPaperTypeID(this.state().productDetails.outerMaterial??"") === "k" ||
      this.getPaperTypeID(this.state().productDetails.innerMaterial??"") === "k"
    ) {
      paperType = "k";
    } else {
      paperType = "kw";
    }
    this.customQuantityEntered = true;

    if (this.isPaddedMailer) {
      this.boxService
        .getPaddedMailerPrice(
          this.state().productDetails.Code??"",
          paperType,
          this.state().quantity,
          false
        )
        .then((value: any) => {
          this.selectedSubcategory = value.paddedMailer.boxType.subcategory;
          if (this.prices && this.prices[0]) {
            this.customQuantityViolation =
              this.state().quantity < (this.prices[0] as Price).minimumOrder ||
              this.state().quantity > this.prices[0].maxQuantity;
          }
          if (!this.customQuantityViolation) {
            // this.selectedPrice = value.price;
            // this.enteredCustomPrice = `${this.state().quantity} cases of ${
            //   this.selectedPrice.unitsPerCase
            // } ($${this.selectedPrice.pricePerUnit.toFixed(2)}) each`;
          }
        })
        .catch(() => {
          this.pageLoading = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else {
      this.boxService
        .getPrice(
          this.state().productDetails.Code??"",
          this.state().productDetails.BoardStrength??"",
          paperType,
          this.length,
          this.width,
          this.depth,
          this.state().quantity,
          this.state().productDetails.twoSided??false,
          this.state().productDetails.luxe??false,
          this.state().productDetails.gloss??false,
          false,
          this.state().productDetails.options,
          false
        )
        .then((value) => {
          if (
            !this.state().productDetails.luxe &&
            this.prices &&
            this.prices[0] &&
            (this.state().quantity < (this.prices[0] as Price).minimumOrder ||
              this.state().quantity > this.prices[0].maxQuantity)
          ) {
            this.customQuantityViolation = true;
          } else if (
            this.state().productDetails.luxe &&
            this.prices &&
            this.prices[0] &&
            (this.state().quantity < (this.prices[0] as Price).luxeMinimumOrder ||
              this.state().quantity > this.prices[0].maxQuantity)
          ) {
            this.customQuantityViolation = true;
          } else {
            this.customQuantityViolation = false;
          }

          if (!this.customQuantityViolation) {
            // this.selectedPrice = value.price;
            // this.enteredCustomPrice =
            //   this.state().quantity +
            //   ' ($' +
            //   this.selectedPrice.pricePerUnit.toFixed(2) +
            //   ') each';
          }
        })
        .catch(() => {
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  addToCart() {
    this.cartLoading = true;
    if (this.isTissue) {
      this.state().quantity /= 100;
      this.selectedPrice.quantity /= 100;
    }
    let resolveObj = {};
    try {
      resolveObj = this.prepareOrderDesignPromise(resolveObj);
      this.createOrderDesignPromise(resolveObj).then(
        (response) => {
          response.quantity = this.selectedPrice.quantity;
          this.addCartItem(response);
        },
        () => {
          this.cartLoading = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        }
      );
    } catch (e) {
      this.cartLoading = false;
      this.bsModalService
        .show(ConfirmationModalComponent)
        .content?.showModal(ERROR_BODY, ERROR_TITLE);
    }
  }
  public trackByFn(index: number, item: any) {
    if (!item) {
      return index;
    } else {
      //return item.id;
      return index;
    }
  }
  numberOnly(event: { which: any; keyCode: any }) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  prepareOrderDesignPromise(resolveObj: any) {


    this.imagesService
      .getFileContent(
        this.state().container ?? "",
        this.state().outsideFileName ?? ""
      )
      .then((res: { content: any }) => {
        resolveObj.outsideCanvasJSON = res.content;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
    this.imagesService
      .getFileContent(
        this.state().container ?? "",
        this.state().insideFileName ?? ""
      )
      .then((res: { content: any }) => {
        resolveObj.insideCanvasJSON = res.content;
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    resolveObj.twoSided = this.state().productDetails.twoSided;
    resolveObj.containerName = this.state().productDetails.ContainerName;
    resolveObj.boxCode = this.state().productDetails.Code;
    resolveObj.dimensions = this.state().productDetails.BoxDimensions;
    resolveObj.boardType = this.state().productDetails.Code;
    resolveObj.boardStrength = this.state().productDetails.BoardStrength;
    resolveObj.kraftType = this.state().productDetails.paperType;
    resolveObj.secondaryKraftType = this.state().productDetails.secondaryPaperType;
    resolveObj.preset = this.state().productDetails.Preset;
    resolveObj.gloss = this.state().productDetails.gloss;
    resolveObj.luxe = this.state().productDetails.luxe;
    resolveObj.quantity = this.state().quantity;
    resolveObj.rush = this.state().productDetails.rush;
    if (this.state().productDetails.Subcategory === "SNAP2SF") {
      resolveObj.options = "superseal";
    } else if (this.state().productDetails.Subcategory === "SNAP2RSF") {
      resolveObj.options = "superseal";
      {
        resolveObj.options = "";
      }
    }
    resolveObj.insideOnly = this.state().productDetails.insideOnly;
    resolveObj.filename = new Date().getTime();

    return resolveObj;
  }

  createOrderDesignPromise(resolveObj: any) {
    const design = {
      customerId: resolveObj.customerID,
      container: resolveObj.containerName,
      type: "BASIC",
      outside: resolveObj.outsideCanvasJSON,
      inside: resolveObj.insideCanvasJSON,
      name: "Auto Saved",
      dimensions: resolveObj.dimensions,
      boardType: resolveObj.boardType,
      boardStrength: resolveObj.boardStrength,
      kraftType: resolveObj.kraftType,
      secondaryKraftType: resolveObj.secondaryKraftType,
      gloss: resolveObj.gloss,
      luxe: resolveObj.luxe,
      twoSided: resolveObj.twoSided,
      preset: resolveObj.preset,
      options: resolveObj.options,
      filename: resolveObj.filename,
      rush: resolveObj.rush,
    };
    return new Promise<any>((resolve, reject) => {
      this.projectService
        .createOrderItem(design)
        .then((response: { container: any; type: any; preset: any }) => {
          resolveObj.design = response;
          resolveObj.containerName = response.container;
          resolveObj.type = response.type;
          resolveObj.preset = response.preset;

          if (this.isPaddedMailer) {
            this.checkoutService
              .createPaddedMailer(
                resolveObj.containerName,
                resolveObj.boxCode,
                resolveObj.boardStrength,
                resolveObj.kraftType,
                resolveObj.filename,
                this.length,
                this.width,
                this.depth,
                true,
                false,
                resolveObj.quantity
              )
              .then((paddedMailer: any) => {
                resolveObj.paddedMailer = paddedMailer;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          } else {
            this.checkoutService
              .createBox(
                resolveObj.containerName,
                resolveObj.boxCode,
                resolveObj.boardStrength,
                resolveObj.kraftType,
                resolveObj.secondaryKraftType,
                resolveObj.gloss,
                resolveObj.luxe,
                resolveObj.filename,
                this.length,
                this.width,
                this.depth,
                resolveObj.preset,
                resolveObj.twoSided,
                resolveObj.insideOnly,
                resolveObj.rush,
                resolveObj.options,
                resolveObj.quantity
              )
              .then((box: any) => {
                resolveObj.box = box;
                resolve(resolveObj);
              })
              .catch((reason: any) => {
                reject(reason);
              });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  async addCartItem(resolveObj: any) {
    let lineItem = {};
    try {
      if (this.isTape) {
        resolveObj.tape.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createTapeLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.tape
        );
      } else if (this.isTissue) {
        resolveObj.tissue.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createTissueLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.tissue
        );
      } else if (this.isLabel) {
        resolveObj.label.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createLabelLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.label
        );
      } else if (this.isPaddedMailer) {
        resolveObj.paddedMailer.quantity = this.selectedPrice.quantity;
        lineItem = this.checkoutService.createPaddedMailerLineItem(
          resolveObj.containerName,
          this.boxData,
          resolveObj.paddedMailer
        );
      } else {
        resolveObj.box.quantity = this.selectedPrice.quantity;

        if (
          this.boxData.aeCode === "SNAP2" ||
          this.boxData.aeCode === "SNAP2R"
        ) {
          await this.containerService
            .getContainerInfo(this.state().productDetails.ContainerName)
            .then((containerInfo: { templateName: string }) => {
              this.boxData.aeCode = containerInfo.templateName.substr(
                0,
                containerInfo.templateName.indexOf("_")
              );
              lineItem = this.checkoutService.createLineItem(
                resolveObj.containerName,
                this.boxData,
                resolveObj.box
              );
            })
            .catch(() => {
              this.bsModalService
                .show(ConfirmationModalComponent)
                .content?.showModal(ERROR_BODY, ERROR_TITLE);
            });
        } else {
          lineItem = this.checkoutService.createLineItem(
            resolveObj.containerName,
            this.boxData,
            resolveObj.box
          );
        }
      }
    } catch (e) {
      console.log(e);
      this.cartLoading = false;
      this.bsModalService
        .show(ConfirmationModalComponent)
        .content?.showModal(ERROR_BODY, ERROR_TITLE);
      return;
    }

    this.checkoutService
      .addToCart(lineItem)
      .then((payload: any) => {
        this.cartUrl.set(payload.url);
        this.cxmlurlencoded.set(payload.content);
        setTimeout(() => {
          this.addToCartForm.nativeElement.submit();
        });

        this.stateSvc.updateState({insideFileName:"",outsideFileName:""});

        // this.router.navigate(["/cart"]);
      })
      .catch(() => {
        this.bsModalService
          .show(ConfirmationModalComponent)
          .content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  showModal(template: TemplateRef<any>) {
    this.modal = this.bsModalService.show(template, { class: "modal-sm" });
  }
  hide() {
    this.modal.hide();
  }

  getWholeNumber(fraction: any) {
    const temp = String(fraction).split(".", 2);
    return parseFloat(temp[0]);
  }

  convertDecimalToFraction(num: any) {
    return Fractions.convertDecimalToFraction(num);
  }
}
