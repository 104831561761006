import { Routes } from '@angular/router';
import { BoxStyleComponent } from './pages/box-style/box-style.component';
import { ReviewBoxComponent } from './pages/review-box/review-box.component';
import { DesignComponent } from './pages/design/design.component';
import { BoxOptionsComponent } from './pages/box-options/box-options.component';
import { UploadDesignComponent } from './pages/upload-design/upload-design.component';
import { EmptyComponent } from './pages/empty/empty.component';
import { BoxEditComponent } from './pages/box-edit/box-edit.component';
import { requireSessionID, requireLogin, refreshCustomerState } from './auth.gaurd';

export const routes: Routes = [
  {
    path: "box-style/:categoryCode",
    component: BoxStyleComponent,
    canActivate: [requireSessionID()],
    data: {
      title: "Box Style",
    },
  },

  {
    path: "box-style/:categoryCode/:uploadDesign",
    component: BoxStyleComponent,
    canActivate: [requireSessionID()],
    data: {
      title: "Box Style",
    },
  },

  {
    path: "box-options/:categoryCode/:subcategoryCode/:uploadDesign",
    component: BoxOptionsComponent,
    canActivate: [refreshCustomerState(), requireSessionID()],
    data: {
      title: "Box Options",
    },
  },

  {
    path: "box-options",
    component: BoxOptionsComponent,
    canActivate: [refreshCustomerState(), requireSessionID()],
    data: {
      title: "Box Options",
    },
  },
  {
    path: "design",
    component: DesignComponent,
    canActivate: [refreshCustomerState(), requireLogin()],
  },
  {
    path: "review-box",
    component: ReviewBoxComponent,
    canActivate: [refreshCustomerState(), requireLogin()],
    data: {
      title: "Box Style",
    },
  },
  {
    path: "upload-design",
    component: UploadDesignComponent,
    canActivate: [refreshCustomerState(), requireLogin()],
    data: {
      title: "Upload Design",
    },
  },
  {
    path: "upload-design/:category/:subcategory",
    canActivate: [refreshCustomerState(), requireLogin()],
    component: UploadDesignComponent,
    data: {
      title: "Upload Design",
    },
  },
  {
    path: "box-edit",
    component: BoxEditComponent,
    canActivate: [requireLogin()],
    data: {
      title: "Edit Order",
    },
  },
  {
    path: "",
    pathMatch: "full",
    component: EmptyComponent,
    data: {
      title: "Custom Box",
    },
  },
];


