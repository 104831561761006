import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ERROR_BODY, ERROR_TITLE } from "../../../../globals";
import { ConfirmationModalComponent } from "../../../features/confirmation-modal/confirmation-modal.component";
import { ImagesService } from "../../../services/images.service";
import { User } from "../../../shared/models/user";
import { ConverterService } from "../../../services/converter.service";
import { NgxDropzoneModule } from "ngx-dropzone";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-image-upload",
    templateUrl: "./image-upload.component.html",
    styleUrls: ["./image-upload.component.scss"],
    imports: [CommonModule, NgxDropzoneModule]
})
export class ImageUploadComponent implements OnInit {
  @Output() imageAdded: EventEmitter<any> = new EventEmitter();
  @Output() hideModal: EventEmitter<any> = new EventEmitter();

  @Input() containerID: string = "";
  @Input() user: User | undefined;

  private files: File[] = [];
  public uploadInProgress = false;
  public conversionProgress = 0;
  public uploadProgress = 0;
  public imageUploadError: string = "";

  constructor(
    private imagesService: ImagesService,
    private converterService: ConverterService,
    private bsModalService: BsModalService
  ) {}

  ngOnInit() {}

  async processImage(event: { addedFiles: any[] }) {
    console.log(event);
    this.imageUploadError = "";
    if (event.addedFiles[0].size < 10485760) {
      this.files.push(...event.addedFiles);

      const formData: FormData = new FormData();
      this.uploadInProgress = true;
      this.conversionProgress = 0;
      this.uploadProgress = 0;

      await this.converterService
        .checkFormat(event.addedFiles[0].name)
        .then(async (formatCheck: { usable: any }) => {
          if (formatCheck.usable) {
            this.conversionProgress = 100;

            await this.increaseUploadProgress();

            const uploadDestination = this.converterService.getUploadUrl(
              this.containerID
            );
            formData.append("file", event.addedFiles[0]);
            formData.append("filename", event.addedFiles[0].name);
            this.uploadUsableImage(uploadDestination, formData);
          } else {
            this.conversionProgress = 0;

            await this.increaseConversionProgress();
            await this.increaseUploadProgress();

            const convertDestination = this.converterService.getConvertUrl();
            formData.append("file", event.addedFiles[0]);
            formData.append("filename", event.addedFiles[0].name);

            this.uploadedConvertableImage(convertDestination, formData);
          }
        })
        .catch(() => {
          this.uploadInProgress = false;
          this.bsModalService
            .show(ConfirmationModalComponent)
            .content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else {
      this.imageUploadError = "Image size should not be greater than 10 MB";
    }
  }

  uploadUsableImage(uploadDestination: string, formData: FormData) {
    this.imagesService
      .upload(uploadDestination, formData)
      .then((res) => {
        const file = {
          tempBucketName: res.file.bucket,
          tempFilename: res.file.filename,
          type: res.file.type,
        };
        if (this.user) {
          this.imagesService
            .uploadImageToUserLibrary(file, this.user.id!)
            .then(() => {
              this.imagesService
                .copyImageToContainer(file, this.containerID)
                .then(() => {
                  this.imageAdded.emit();
                  this.uploadInProgress = false;
                })
                .catch(() => {
                  this.uploadInProgress = false;
                  this.bsModalService
                    .show(ConfirmationModalComponent)
                    .content?.showModal(ERROR_BODY, ERROR_TITLE);
                });
            })
            .catch(() => {
              this.uploadInProgress = false;
              this.bsModalService
                .show(ConfirmationModalComponent)
                .content?.showModal(ERROR_BODY, ERROR_TITLE);
            });
        } else {
          this.imagesService
            .uploadImageToContainer(file, this.containerID)
            .then(() => {
              this.imageAdded.emit();
              this.uploadInProgress = false;
            })
            .catch(() => {
              this.uploadInProgress = false;
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  uploadedConvertableImage(convertDestination: string, formData: FormData) {
    this.converterService
      .convert(convertDestination, formData)
      .then((convertedResponse: { jobId: any }) => {
        this.converterService
          .getConvertedContent(convertedResponse.jobId)
          .then(
            (convertedContent: { bucket: any; filename: any; type: any }) => {
              const file = {
                tempBucketName: convertedContent.bucket,
                tempFilename: convertedContent.filename,
                type: convertedContent.type,
              };
              if (this.user) {
                this.imagesService
                  .uploadImageToUserLibrary(file, this.user.id!)
                  .then(() => {
                    this.imagesService
                      .copyImageToContainer(file, this.containerID)
                      .then(() => {
                        this.imageAdded.emit();
                        this.uploadInProgress = false;
                      })
                      .catch((error) => {
                        console.log(error);
                        this.uploadInProgress = false;
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                    this.uploadInProgress = false;
                  });
              } else {
                this.imagesService
                  .uploadImageToContainer(file, this.containerID)
                  .then(() => {
                    this.imageAdded.emit();
                    this.uploadInProgress = false;
                  })
                  .catch((error) => {
                    console.log(error);
                    this.uploadInProgress = false;
                  });
              }
            }
          )
          .catch((error: any) => {
            console.log(error);
            this.uploadInProgress = false;
          });
      })
      .catch((error: any) => {
        console.log(error);
        this.uploadInProgress = false;
      });
  }

  increaseUploadProgress() {
    return new Promise<void>((resolve, reject) => {
      if (this.uploadProgress < 50) {
        this.uploadProgress += 4;
        setTimeout(() => resolve(this.increaseUploadProgress()), 50);
      } else if (this.uploadProgress < 95) {
        this.uploadProgress += 2;
        setTimeout(() => resolve(this.increaseUploadProgress()), 50);
      } else {
        resolve();
      }
    });
  }

  increaseConversionProgress() {
    return new Promise<void>((resolve, reject) => {
      if (this.conversionProgress < 50) {
        this.conversionProgress += 4;
        setTimeout(() => resolve(this.increaseConversionProgress()), 50);
      } else if (this.conversionProgress < 100) {
        this.conversionProgress += 2;
        setTimeout(() => resolve(this.increaseConversionProgress()), 50);
      } else {
        resolve();
      }
    });
  }
}
