<!-- Footer START -->
<footer class="content-footer fedex">
  <div class="footer">
    <div class="copyright">
      <div>
        <span>© Fedex 1995-{{ currentYear }}</span>
      </div>
      <div>
        <a href="https://www.office.fedex.com/default/contact-support">Customer Support</a>
        <span class="footerspacer">|</span>
        <a href="javascript:" (click)="this.showModal(terms)"
          >Terms & Conditions</a
        >
        <span class="footerspacer">|</span>
        <a href="javascript:" (click)="this.showModal(privacy_policy)"
          >Privacy Policy</a
        >
      </div>
    </div>
  </div>
</footer>
<!-- Footer END -->
<ng-template #terms>
  <app-terms></app-terms>
</ng-template>

<ng-template #privacy_policy>
  <app-privacy-policy></app-privacy-policy>
</ng-template>
