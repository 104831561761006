  <header>
    <div class="header-container mobile-only">
      <div class="mobile-header">
        <img class="logo fedex" src="assets/fedex/images/fedex-logo-114x64.png" />
      </div>
    </div>
    <div class="header-container web-tab-only">
      <div class="logo-container">
        <a target="_blank" href="https://custombrandedboxes.fedex.com"
          ><img
            src="assets/fedex/images/fedex-logo-114x64.png"
            alt="Custom Branded Boxes By Fedex Logo"
        /></a>
      </div>
      <div class="progress-bar-container">
        <app-nav-bar></app-nav-bar>
      </div>
    </div>
  </header>