import { BoardStrength } from "./box.js";
import { CustomerChoices } from "./customer-choices.model.ts.js";
import { ProductDetailsModel } from "./product-details.model.js";
import { UploadDetailsModel } from "./upload-details.model.js";

export class ClientSessionStateModel {
  sessionKey: string = "";
  authSessionData: any = {};
  categoryCode = "";
  subcategoryCode = "";
  uploadDesign = false;
  targetService = "";
  productDetails: ProductDetailsModel = {};
  uploadDetails: UploadDetailsModel = {};
  quantity: number = 0;
  container = "";
  outsideFileName = "";
  insideFileName = "";
  optionSpecs: CustomerChoices | undefined = undefined;
  isEdit = false;
}
