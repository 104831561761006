import { Component, OnDestroy, OnInit } from "@angular/core";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { Subscription } from "rxjs";
import { ProjectService } from "../../services/project.service";
import { NGXLogger } from "ngx-logger";
import { BoxService } from "../../services/box.service";
import { ProductDetailsModel } from "../../shared/models/product-details.model";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-box-edit",
    template: ` <div class="page-container">
    <app-page-content-wrapper [goBack]="goBack">
      <div class="page-title">
        <h1></h1>
      </div>
      <div class="page-content"></div>
    </app-page-content-wrapper>
  </div>`,
    styles: [],
    imports: [PageContentWrapperComponent, RouterModule]
})
export class BoxEditComponent implements OnInit, OnDestroy {
  pageLoaded: boolean = false;
  state: any;
  subscriptions = new Subscription();
  private static STANDARD_QUANTITIES = [1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000];

  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private router: Router,
    private stateSvc: ClientSessionStateService,
    private projectService: ProjectService,
    private boxService: BoxService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.state = this.stateSvc.getState();
    this.subscriptions.add(
      this.route.queryParamMap.subscribe(async (params) => {
        const sessionKey: string = params.get("sessionID") ?? params.get("sessionId") ?? "";
        const projectKey: string = params.get("projectID") ?? params.get("projectId") ?? "";
        const isReorder: boolean = Boolean(JSON.parse(params.get("reorder") ?? "false"));
        const quantity: number = parseInt(params.get("quantity") ?? "0");

        if (!sessionKey || !projectKey) {
          // TODO: add error handling
          this.logger.error("Session or product key not found");
        } else {
          let currentProject = await this.projectService.getProjectByContainer(projectKey, false, true, true);

          if (isReorder) {
            await this.rebuildReorderState(currentProject, quantity);
          } else {
            await this.rebuildEditState(currentProject, quantity);
          }
          this.redirectToPage(currentProject.type!, isReorder);
        }
      })
    );
  }
  redirectToPage(type: string, isReorder: boolean = false) {
    if (isReorder) {
      this.logger.info("Redirect to review order page");
      this.router.navigate(["/review-box"]);
    } else if (type === "ADVANCED") {
      this.logger.info("Redirect to advanced design page");
      this.router.navigate(["/upload-design"]);
    } else {
      this.logger.info("Redirect to basic design page");
      this.router.navigate(["/design"]);
    }
  }

  async rebuildEditState(currentProject: any, quantity: number): Promise<void> {
    try {
      this.stateSvc.updateState({ quantity: quantity });
      this.stateSvc.updateState({ isEdit: true, continuedProject: true });

      if (currentProject?.type === "ADVANCED") {
        this.rebuildUploadDesignState(currentProject, quantity);
      } else {
        this.rebuildBasicDesignState(currentProject, quantity);
      }
    } catch (error) {
      this.logger.error("Error getting project", error);
      Promise.reject(error);
    }
  }

  async rebuildReorderState(currentProject: any, quantity: number): Promise<void> {}

  rebuildUploadDesignState(design: any, quantity: number) {
    this.stateSvc.updateState({ uploadDesign: true });
    const paperTypes = this.boxService.getPaperTypes(design.boxType!.subcategory!);

    const uploadDetails: any = {
      Origin: "designer",
      Subcategory: design.boxType.aeCode,
      Code: design.boxType.code,
      BoardStrength: design.boardStrength.strength,
      ContainerId: design.containerName,
      kraftType: design.kraftType,
      secondaryKraftType: design.secondaryKraftType,
      paperType: design.kraftType,
      boxDimensions: design.dimensions,
      gloss: design.gloss,
      preset: design.preset,
      luxe: design.luxe,
      rush: false,
      twoSided: design.twoSided,
      insideOnly: design.insideOnly,
      quantity: quantity,
    };
    if (uploadDetails.Subcategory! in ["SNAP2S", "SNAP2RS", "SNAP2SF", "SNAP2SRF"]) {
      uploadDetails.options = "superseal";
    }

    this.stateSvc.updateState({ uploadDetails: uploadDetails });
  }

  rebuildBasicDesignState(project: any, quantity: number) {
    this.stateSvc.updateState({ uploadDesign: false });
    const dimensions = project.dimensions.split("x");

    const productDetails: ProductDetailsModel = {
      id: project.id,
      Category: project.boxType.subcategory.code,
      Subcategory: project.boxType.aeCode,
      Code: project.boxType.code,
      BoardStrength: project.boardStrength,
      ContainerName: project.container,
      paperType: project.kraftType,
      secondarypaperType: project.secondaryKraftType,
      NewDesignID: project.id,
      BoxDimensions: project.dimensions,
      Preset: project.preset,
      gloss: project.gloss,
      luxe: project.luxe,
      twoSided: project.twoSided,
      insideOnly: project.insideOnly,
      outerMaterial: this.getMaterial(project.kraftType),
      innerMaterial: this.getMaterial(project.secondaryKraftType),
      print: this.getPrinting(project),
      finish: project.gloss ? "Gloss" : "Matte",
      categoryName: project.boxType.name,
      quantity: quantity ? quantity : BoxEditComponent.STANDARD_QUANTITIES[4],
      continuedProject: true,
      name: project.name,
      length: parseInt(dimensions[0]),
      width: parseInt(dimensions[1]),
      depth: parseInt(dimensions[2]),
    };

    if (project.screenshotData) {
      productDetails.screenshotSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/png;base64, ${project.screenshotData}`
      );
    }

    if (productDetails.Subcategory! in ["SNAP2S", "SNAP2RS", "SNAP2SF", "SNAP2SRF"]) {
      productDetails.options = "superseal";
    }
    this.stateSvc.updateState({ productDetails: productDetails });
  }

  ngOnDestroy(): void {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

  goBack() {
    this.logger.info("Go back");
  }

  getMaterial(kraftType: string) {
    if (kraftType === "k") {
      return "Kraft";
    } else if (kraftType === "pkw") {
      return "Premium White";
    } else {
      return "Standard White";
    }
  }
  getPrinting(project: any) {
    return this.getPrintingType(project.luxe) + "|" + this.getPrintingSide(project.twoSided, project.insideOnly);
  }
  getPrintingType(luxe: boolean) {
    if (luxe) {
      return "Luxe";
    } else {
      return "Standard";
    }
  }
  getPrintingSide(twoSided: boolean, insideOnly: boolean) {
    if (twoSided) {
      return "2-Sided";
    } else if (insideOnly) {
      return "Inside";
    } else {
      return "Outside";
    }
  }
}
